// App Shared Icons
import ChromeIcon from '@shared/icons/ChromeFilled';
import MessageSupportIcon from '@shared/icons/MessageSupport';
import VideoCameraIcon from '@shared/icons/VideoCamera';
import TeamsIcon from '@shared/icons/MicrosoftTeamsFilled';
// App Shared Assets
import { ReactComponent as ConnectIcon } from '@shared/assets/resourceCenter/connect-icon.svg';
import { ReactComponent as InviteIcon } from '@shared/assets/resourceCenter/invite-icon.svg';
import { ReactComponent as RecordIcon } from '@shared/assets/resourceCenter/record-icon.svg';
import { ReactComponent as UploadIcon } from '@shared/assets/resourceCenter/upload-icon.svg';
import { ReactComponent as FacebookIcon } from '@shared/assets/resourceCenter/socials/facebook.svg';
import { ReactComponent as TwitterIcon } from '@shared/assets/resourceCenter/socials/twitter.svg';
import { ReactComponent as YouTubeIcon } from '@shared/assets/resourceCenter/socials/youtube.svg';
import { ReactComponent as LinkedInIcon } from '@shared/assets/resourceCenter/socials/linkedIn.svg';
import { ReactComponent as InstagramIcon } from '@shared/assets/resourceCenter/socials/instagram.svg';

export const RESOURCE_CENTER_ELEMENTS = {
  tutorials: [
    {
      icon: <InviteIcon width="48" height="49" />,
      url: 'https://www.youtube.com/embed/iTQVs9CEie0',
      title: 'How to invite Sembly to a meeting',
      description: 'Zoom, Google Meet, Microsoft Teams and other',
    },
    {
      icon: <RecordIcon width="48" height="49" />,
      url: 'https://www.youtube.com/embed/TRUYmTYjiTE',
      title: 'How to record a meeting with a mic',
      description: 'Use your mic to record any meeting',
    },
    {
      icon: <UploadIcon width="48" height="49" />,
      url: 'https://www.youtube.com/embed/eNGZxjpTH1I',
      title: 'How to upload an audio file',
      description: 'Upload past meeting or any audio file',
    },
    {
      icon: <ConnectIcon width="48" height="49" />,
      url: 'https://www.youtube.com/embed/JPa3vjvgbgc',
      title: 'How to connect your calendar',
      description: 'Sync Sembly with entries in your calendar',
    },
  ],
  support: [
    {
      icon: <MessageSupportIcon />,
      link: null,
      text: 'Message support',
    },
    {
      icon: <VideoCameraIcon />,
      link: 'https://www.youtube.com/channel/UCC2SojuAyLz9rlFtpExDFBQ/videos',
      text: 'Youtube channel',
    },
  ],
  promotedPlatforms: [
    {
      icon: <TeamsIcon />,
      link: 'https://appsource.microsoft.com/en-us/product/office/WA200004826',
      text: 'Microsoft Teams App',
    },
    {
      icon: <ChromeIcon />,
      link: 'https://chrome.google.com/webstore/detail/sembly-ai-meeting-notes-f/kgncmlkaopailaafofodciacppkjcenl',
      text: 'Chrome Extension',
    },
  ],
  socialMedia: [
    {
      icon: <LinkedInIcon />,
      link: 'https://www.linkedin.com/company/semblyai',
      text: 'LinkedIn',
    },
    {
      icon: <TwitterIcon />,
      link: 'https://twitter.com/sembly_ai',
      text: 'Twitter',
    },
    {
      icon: <InstagramIcon />,
      link: 'https://www.instagram.com/semblyai/',
      text: 'Instagram',
    },
    {
      icon: <FacebookIcon />,
      link: 'https://www.facebook.com/SemblyAI/',
      text: 'Facebook',
    },
    {
      icon: <YouTubeIcon />,
      link: 'https://www.youtube.com/channel/UCC2SojuAyLz9rlFtpExDFBQ',
      text: 'YouTube',
    },
  ],
} as const;

export default RESOURCE_CENTER_ELEMENTS;
