import { useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Material Icons
import AddIcon from '@material-ui/icons/Add';
import RecordIcon from '@material-ui/icons/Mic';
import TodayIcon from '@material-ui/icons/Today';
// App Shared
import UploadIcon from '@shared/icons/Upload';
import meetingPlatforms from '@shared/assets/platforms.svg';

type ActionType = 'invite-sembly' | 'record-meeting' | 'upload-meeting';

export interface AppMissionControlProps {
  disabled?: boolean;
  onInviteSemblyAgent: () => void;
  onRequestMeetingRecording: () => void;
  onRequestMeetingUploading: () => void;
}

export const AppMissionControl: React.VFC<AppMissionControlProps> = ({
  disabled = false,
  onInviteSemblyAgent,
  onRequestMeetingRecording,
  onRequestMeetingUploading,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  /* #endregion */

  /* #region  Handlers */
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleClickOnMenuItem = (action: ActionType) => () => {
    // prettier-ignore
    switch (action) {
      case 'invite-sembly': onInviteSemblyAgent(); break;
      case 'record-meeting': onRequestMeetingRecording(); break;
      case 'upload-meeting': onRequestMeetingUploading(); break;
    }
    handleCloseMenu();
  };
  /* #endregion */

  return (
    <>
      <Button
        disableElevation
        color="primary"
        variant="contained"
        className={styles.button}
        startIcon={<AddIcon />}
        aria-label="Start new Meeting"
        aria-haspopup="true"
        aria-controls="main-actions-menu"
        disabled={disabled}
        onClick={handleOpenMenu}
      >
        <Tooltip title="Start new Meeting">
          <Typography noWrap component="span" variant={isSmallScreen ? 'body1' : 'inherit'}>
            New Meeting
          </Typography>
        </Tooltip>
      </Button>
      {/* Begin: Menu */}
      <Menu
        id="main-actions-menu"
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: styles.menuPaper }}
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
      >
        {MENU_ITEMS.map(({ label, icon, action }) => (
          <MenuItem key={action} onClick={handleClickOnMenuItem(action)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      {/* End: Menu */}
    </>
  );
};

const MENU_ITEMS = [
  {
    label: (
      <Box display="flex" alignItems="center" gridGap={14}>
        <span>Invite Sembly</span>
        <img src={meetingPlatforms} width={80} height={24} alt="Meeting platforms" />
      </Box>
    ),
    icon: <TodayIcon fontSize="small" />,
    action: 'invite-sembly',
  },
  { label: 'Record audio', icon: <RecordIcon fontSize="small" />, action: 'record-meeting' },
  { label: 'Upload media file', icon: <UploadIcon fontSize="small" />, action: 'upload-meeting' },
] as const;

const useStyles = makeStyles((theme) => {
  return {
    menuPaper: {
      borderRadius: theme.shape.borderRadius,
    },
    menuItem: {
      padding: theme.spacing(1, 2),
    },
    button: {
      position: 'relative',
      alignSelf: 'center',
    },
  };
});

export default AppMissionControl;
