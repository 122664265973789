import { useState, useEffect } from 'react';
import { Calendar, CalendarProps, DayValue } from '@amir04lm26/react-modern-calendar-date-picker';
// Material UI
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import CloseIcon from '@material-ui/icons/Close';

export interface DayCalendarPopoverProps {
  anchorEl: HTMLElement;
  value: Date | string | null;
  calendarProps?: Omit<CalendarProps<DayValue>, 'value' | 'onChange'>;
  popoverProps?: PopoverProps;
  confirmText?: string;
  onChange: (value: Date | null) => void;
  onClose: () => void;
}

export const DayCalendarPopover: React.VFC<DayCalendarPopoverProps> = ({
  anchorEl,
  value,
  calendarProps = {},
  popoverProps = {
    open: true,
    getContentAnchorEl: null,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    transformOrigin: { vertical: 'center', horizontal: 'center' },
  },
  confirmText = 'Select',
  onChange,
  onClose,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [selectedDay, setSelectedDay] = useState<DayValue>(null);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleChangeDate = (day: DayValue) => {
    setSelectedDay(day);
  };

  const handleSelectCalendarValue = () => {
    if (!selectedDay) return onClose();

    const dueDate = new Date(
      selectedDay.year,
      selectedDay.month - 1, // convert a month number to a month index
      selectedDay.day,
    );

    onChange(dueDate);
    onClose();
  };

  const handleClickOnFilterMenuItem = (type: string) => () => {
    const today = new Date();
    let dueDate: Date | null = null;

    switch (type) {
      case 'today':
        dueDate = today;
        break;
      case 'yesterday':
        dueDate = new Date(today.setDate(today.getDate() - 1));
        break;
      case 'last-week':
        dueDate = new Date(today.setDate(today.getDate() - 7));
        break;
      case 'last-month':
        dueDate = new Date(today.setMonth(today.getMonth() - 1));
        break;
      case 'last-3-months':
        dueDate = new Date(today.setMonth(today.getMonth() - 3));
        break;
      case 'last-year':
        dueDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case 'specific-date':
        setIsShowCalendar(true);
        return;
      default:
        throw new Error('Invalid filter type');
    }

    onChange(dueDate);
    onClose();
  };
  /* #endregion */

  useEffect(() => {
    if (value && typeof value === 'object') {
      setSelectedDay({
        day: value.getDate(),
        month: value.getMonth() + 1, // convert a month index to a month number
        year: value.getFullYear(),
      });
    }
  }, [value]);

  return (
    <>
      {!isShowCalendar ? (
        <Menu
          {...popoverProps}
          anchorEl={anchorEl}
          classes={{ paper: styles.menuPaper }}
          onClose={onClose}
        >
          <MenuItem disabled>Jump to...</MenuItem>
          <MenuItem onClick={handleClickOnFilterMenuItem('today')}>Today</MenuItem>
          <MenuItem onClick={handleClickOnFilterMenuItem('yesterday')}>Yesterday</MenuItem>
          <MenuItem onClick={handleClickOnFilterMenuItem('last-week')}>Last Week</MenuItem>
          <MenuItem onClick={handleClickOnFilterMenuItem('last-month')}>Last Month</MenuItem>
          <MenuItem onClick={handleClickOnFilterMenuItem('specific-date')}>
            To Specific Date
          </MenuItem>
        </Menu>
      ) : (
        <Popover
          {...popoverProps}
          anchorEl={anchorEl}
          classes={{ paper: styles.paper }}
          onClose={onClose}
        >
          <div className={styles.header}>
            <Typography variant="h6">Select a Date</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>

          <Calendar {...calendarProps} value={selectedDay} onChange={handleChangeDate} />
          <div className={styles.actions}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={styles.button}
              disabled={!selectedDay}
              onClick={handleSelectCalendarValue}
            >
              <Typography variant="body1">{confirmText}</Typography>
            </Button>
            <Button disableElevation variant="text" className={styles.button} onClick={onClose}>
              <Typography variant="body1">Cancel</Typography>
            </Button>
          </div>
        </Popover>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: '1em',
  },
  paper: {
    borderRadius: '2em',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    minWidth: 105,
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

export default DayCalendarPopover;
