import { parseISO } from 'date-fns';
// Material UI
import Box, { BoxProps } from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material Icon
import LoopIcon from '@material-ui/icons/Loop';
// Lib Shared
import { formatInTimeZone } from '../utils';

export type MeetingStatusInfoProps = BoxProps & {
  finishDate: string | null;
  isRecurrentMeeting: boolean;
  labelProps?: TypographyProps;
  ownerFullName: string | null;
  showStartDate?: boolean;
  startDate: string | null;
};

export const MeetingStatusInfo: React.VFC<MeetingStatusInfoProps> = ({
  finishDate,
  isRecurrentMeeting,
  labelProps,
  ownerFullName,
  showStartDate,
  startDate,
  ...props
}) => {
  const styles = useStyles();
  if (!startDate) {
    return null;
  }
  /* #region  Render Helpers */
  const startedAt = startDate || new Date().toISOString();
  const finishedAt = finishDate || startedAt;
  const startedDate = formatInTimeZone(parseISO(startedAt), 'PP');
  const startedTime = formatInTimeZone(parseISO(startedAt), 'p');
  const finishedTime = formatInTimeZone(parseISO(finishedAt || startedAt), 'p');
  const hasTimeRange = startedTime !== finishedTime;
  const time = hasTimeRange ? `${startedTime} – ${finishedTime}` : startedTime;
  /* #endregion */

  return (
    <Box
      color="text.secondary"
      display="flex"
      flexWrap="wrap"
      style={{ cursor: 'default', ...props.style }}
      {...props}
    >
      {showStartDate && (
        <Typography noWrap className={styles.label} {...labelProps}>
          {startedDate}
        </Typography>
      )}

      <Typography noWrap className={styles.label} {...labelProps}>
        {time}
      </Typography>

      <Typography noWrap className={styles.label} {...labelProps}>
        {ownerFullName}
      </Typography>

      {isRecurrentMeeting && (
        <div className={styles.label}>
          <Tooltip title="Recurring Meeting">
            <LoopIcon color="inherit" fontSize="small" />
          </Tooltip>
        </div>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '&:not(:last-child):after': {
      content: "'•'",
      display: 'inline-flex',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

export default MeetingStatusInfo;
