import { useApolloClient, useMutation } from '@apollo/client';
import { addBreadcrumb } from '@sentry/react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Sembly UI
import { TransitionSlide } from '@sembly-ui';
// App Shared
import { LocalMeetingRecorder, LocalMeetingRecorderChunk } from '@shared/components';
import SuccessfullySubmittedRecording from '@shared/dialogs/SuccessfullySubmittedRecording';
import { Routes } from '@shared/enums';
import { useUserContext, useUserInterface } from '@shared/hooks';
import { LocationState } from '@shared/types';
import { graphErrorHorsemen, isDev } from '@shared/utils';
// GraphQL Queries and Types
import { RecordDevices } from '@gql-types';
import { DeleteMeeting, DeleteMeetingVariables } from '@gql-types/DeleteMeeting';
import { StartLiveMeeting, StartLiveMeetingVariables } from '@gql-types/StartLiveMeeting';
import {
  SubmitMeetingRecordingChunks,
  SubmitMeetingRecordingChunksVariables,
} from '@gql-types/SubmitMeetingRecordingChunks';
import deleteMeetingMutation from '@shared/queries/DeleteMeeting.graphql';
import startLiveMeetingMutation from '@shared/queries/StartLiveMeeting.graphql';
import submitRecordingChunkMutation from '@shared/queries/SubmitMeetingRecordingChunks.graphql';

export interface AudioRecorderProps {
  open: boolean;
  onClose: () => void;
}

export const AudioRecorder: React.FC<AudioRecorderProps> = ({ open, onClose }) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();
  const apolloClient = useApolloClient();
  const history = useHistory<LocationState>();
  const { isOpenRecorder, update } = useUserInterface();

  const recordingMeetingIdRef = useRef<string | null>(null);

  const [shownSubmissionCompleted, setShownSubmissionCompleted] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  /* #endregion */

  /* #region  Mutations */
  const [startLiveMeeting, { loading: isStartingMeeting }] = useMutation<
    StartLiveMeeting,
    StartLiveMeetingVariables
  >(startLiveMeetingMutation);

  const [submitRecordingChunk] = useMutation<
    SubmitMeetingRecordingChunks,
    SubmitMeetingRecordingChunksVariables
  >(submitRecordingChunkMutation);

  const [deleteMeeting, { loading: isDeletingMeeting }] = useMutation<
    DeleteMeeting,
    DeleteMeetingVariables
  >(deleteMeetingMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleCancelRecording = () => {
    update({ isOpenRecorder: false });
    addBreadcrumb({
      category: 'recorder',
      message: 'Recording canceled',
      level: 'info',
    });
    onClose();
  };

  const handleCloseDialog = (event: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    onClose();
  };

  const handleStartRecording = async (variables: StartLiveMeetingVariables) => {
    recordingMeetingIdRef.current = null;

    const { data } = await startLiveMeeting({ variables });

    if (!data?.startLiveMeeting?.meeting) {
      graphErrorHorsemen(data?.startLiveMeeting?.errors);

      addBreadcrumb({
        category: 'recorder',
        message: 'Recording start failed',
        level: 'warning',
      });

      return false;
    } else {
      recordingMeetingIdRef.current = data.startLiveMeeting.meeting.id;

      addBreadcrumb({
        category: 'recorder',
        message: 'Recording start success',
        level: 'info',
      });

      return true;
    }
  };

  const handleSubmitRecordingChunk = async (chunk: LocalMeetingRecorderChunk) => {
    if (!recordingMeetingIdRef.current) return;
    if (chunk.isRecordingCompleted) setIsSubmitting(true);

    const file = new File([chunk.blob], `${chunk.title}.wav`);

    const { data } = await submitRecordingChunk({
      variables: {
        file,
        meetingId: recordingMeetingIdRef.current,
        startedAt: chunk.startedAt,
        finishedAt: chunk.finishedAt,
        isLastChunk: chunk.isRecordingCompleted,
      },
    });

    setIsSubmitting(false);

    if (!data?.submitMeetingRecordingChunks?.success) {
      const error = graphErrorHorsemen(data?.submitMeetingRecordingChunks?.errors);
      window.onbeforeunload = null;
      history.replace(Routes.ErrorGeneric, {
        error: {
          title: 'There was an error during recording',
          message: error || 'Please try recording again',
        },
      });

      addBreadcrumb({
        category: 'recorder',
        message: 'Recording chunk submiting failed',
        level: 'warning',
      });
    }

    addBreadcrumb({
      category: 'recorder',
      message: 'Recording chunk submiting success',
      level: 'info',
    });

    if (chunk.isMaxDurationReached) return;

    // complete recording / submit
    if (chunk.isRecordingCompleted && data?.submitMeetingRecordingChunks?.meeting?.id) {
      update({ isOpenRecorder: false });
      setShownSubmissionCompleted(data.submitMeetingRecordingChunks.meeting.id);
      addBreadcrumb({
        category: 'recorder',
        message: 'Recording completed and submitted',
        level: 'info',
      });
    }
  };

  const handleDeleteRecord = async () => {
    update({ isOpenRecorder: false });

    if (!recordingMeetingIdRef.current) return;

    const meetingId = parseInt(recordingMeetingIdRef.current, 10);
    recordingMeetingIdRef.current = null;

    const result = await deleteMeeting({
      variables: { meetingId },
      update: (cache) => {
        cache.evict({ id: cache.identify({ __typename: 'DetailedMeetingType', id: meetingId }) });
        cache.gc();
      },
    });

    if (!result.data?.deleteMeeting?.success) {
      graphErrorHorsemen(result.data?.deleteMeeting?.errors);
      addBreadcrumb({
        category: 'recorder',
        message: 'Recording delete failed',
        level: 'warning',
      });
    } else {
      addBreadcrumb({
        category: 'recorder',
        message: 'Recording delete success',
        level: 'info',
      });
    }

    onClose();
  };

  const handleCloseMeetingSubmittedDialog = () => {
    setShownSubmissionCompleted(null);

    // update meeting list on home page
    apolloClient.refetchQueries({
      updateCache(cache) {
        cache.modify({
          fields: {
            myMeetingsPaginated(value, { DELETE }) {
              return DELETE;
            },
          },
        });
      },
    });

    onClose();
  };
  /* #endregion */

  /* #region  Helpers */
  const isSmartMikeDeviceUser = user.data?.me?.philipsDevices?.smartmike ?? false;
  const isSmartMeetingDeviceUser = user.data?.me?.philipsDevices?.smartmeeting ?? false;
  const isSmartDeviceUser = isSmartMeetingDeviceUser || isSmartMikeDeviceUser;
  const deviceType = isSmartMikeDeviceUser ? RecordDevices.smartmike : RecordDevices.smartmeeting;
  const recordingDeviceType = isSmartDeviceUser ? deviceType : undefined;
  const recordingMaxDurationSeconds = isDev() ? 300 : 7200; // 5 minutes for dev, 2 hours for prod

  const getLoadingMessage = () => {
    if (isStartingMeeting) return 'Starting recording...';
    if (isDeletingMeeting) return 'Deleting recording...';
    return 'Submitting recording...';
  };
  /* #endregion */

  return (
    <>
      {shownSubmissionCompleted ? (
        <SuccessfullySubmittedRecording
          open={!!shownSubmissionCompleted}
          meetingId={shownSubmissionCompleted}
          onClose={handleCloseMeetingSubmittedDialog}
        />
      ) : (
        <Dialog
          fullWidth
          keepMounted
          open={open}
          maxWidth="sm"
          scroll="paper"
          fullScreen={isSmallScreen}
          TransitionComponent={TransitionSlide}
          onClose={handleCloseDialog}
        >
          <div className={styles.root}>
            {(isStartingMeeting || isDeletingMeeting || isSubmitting) && (
              <div className={styles.loading}>
                <CircularProgress />
                <Typography variant="body2">{getLoadingMessage()}</Typography>
              </div>
            )}
            <LocalMeetingRecorder
              ui="full"
              restartRecordingAtMaxDuration
              forceRecordingStart={isOpenRecorder}
              initializingMeeting={isStartingMeeting}
              maxDurationSeconds={recordingMaxDurationSeconds}
              recordingDeviceType={recordingDeviceType}
              onCancelRecording={handleCancelRecording}
              onDataAvailable={handleSubmitRecordingChunk}
              onDeleteRecord={handleDeleteRecord}
              onError={handleCancelRecording}
              onStartRecording={handleStartRecording}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.surface.main,
    color: theme.palette.surface.contrastText,
    minHeight: '300px',
    height: '100%',
    overflow: 'hidden',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(8),
    margin: theme.spacing(8, 0),
  },
}));

export default AudioRecorder;
