import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { formatISO, parseISO, startOfYear, endOfYear, addDays, subMonths, isValid } from 'date-fns';
// Material UI
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { Switch } from '@sembly-ui';
// Icons
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
// Sembly UI
import { RenameModalDialog } from '@sembly-ui';
// App Shared
import { AppBar } from '@shared/components';
import { graphErrorHorsemen } from '@shared/utils';
import { useUserInterface, useChatPage } from '@shared/hooks';
import { APP_DRAWER_WIDTH } from '@shared/configuration';
import { Routes } from '@shared/enums';
// GraphQL Queries and Types
import query from '@shared/queries/ChatToolSettings.graphql';
import deleteMutation from '@shared/queries/DeleteContextChat.graphql';
import editMutation from '@shared/queries/EditContextChat.graphql';
import manageMutation from '@shared/queries/ManageChatToolSettings.graphql';
import {
  ChatMeetingsLibraryRange as DataRange,
  ChatToolSettings,
  ChatToolSettingsVariables,
  DeleteContextChat,
  DeleteContextChatVariables,
  EditContextChat,
  EditContextChatVariables,
  ManageChatToolSettings,
  ManageChatToolSettingsVariables,
} from '@gql-types';

export const ChatPageLayout: React.FC = ({ children }) => {
  /* #region  Hooks */
  const history = useHistory();
  const { update } = useUserInterface();
  const { chatId, chatTitle } = useChatPage();

  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);
  const [titleAnchorEl, setTitleAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditingChat, setIsEditingChat] = useState<boolean>(false);

  const { data, loading } = useQuery<ChatToolSettings, ChatToolSettingsVariables>(query, {
    variables: { chatId: chatId! },
    skip: !chatId,
  });

  const [deleteChat] = useMutation<DeleteContextChat, DeleteContextChatVariables>(deleteMutation);
  const [editChat] = useMutation<EditContextChat, EditContextChatVariables>(editMutation);
  const [manageChat] = useMutation<ManageChatToolSettings, ManageChatToolSettingsVariables>(
    manageMutation,
  );
  /* #endregion */

  /* #region  Handlers */
  const handleDrawerOpen = () => {
    update({ isAppDrawerOpen: true });
  };

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleOpenTitleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTitleAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setActionsAnchorEl(null);
  };

  const handleCloseTitleMenu = () => {
    setTitleAnchorEl(null);
  };

  const handleClickOnRenameChat = () => {
    setActionsAnchorEl(null);
    setIsEditingChat(true);
  };

  const handleChangeChatName = async (newName: string) => {
    const response = await editChat({
      variables: { chatId: +chatId!, name: newName },
    });

    if (response.data?.editChat?.success) {
      setIsEditingChat(false);
    } else {
      graphErrorHorsemen(response.data?.editChat?.errors);
    }
  };

  const handleDeleteChat = async () => {
    const response = await deleteChat({
      variables: { chatId: +chatId! },
      update: (cache) => {
        const id = cache.identify({ __typename: 'ChatType', id: chatId });
        cache.evict({ id });
        cache.gc();
      },
    });

    if (response.data?.deleteChat?.success) {
      toast.success('Chat deleted successfully');
      history.replace(Routes.MyChats);
    } else {
      graphErrorHorsemen(response.data?.deleteChat?.errors);
    }
  };

  const handleUpdateSettings = async (variables: ManageChatToolSettingsVariables) => {
    const dateFrom = variables.meetingsLibraryRange?.dateFrom ?? null;
    const dateTo = variables.meetingsLibraryRange?.dateTo ?? null;
    const rangeType = variables.meetingsLibraryRangeType;
    const hasCustomRange = rangeType === DataRange.CUSTOM && !!dateFrom && !!dateTo;

    const response = await manageChat({
      variables: {
        chatId: chatId!,
        webSearchEnabled: variables.webSearchEnabled,
        meetingsLibraryEnabled: variables.meetingsLibraryEnabled,
        meetingsLibraryRange: hasCustomRange ? { dateTo, dateFrom } : null,
        meetingsLibraryRangeType: variables.meetingsLibraryRangeType,
      },
      optimisticResponse: {
        manageChatToolSettings: {
          __typename: 'ManageChatToolSettingsMutationPayload',
          success: true,
          errors: null,
          chat: {
            __typename: 'ChatType',
            id: chatId!,
            toolsSettings: {
              __typename: 'ChatToolsSettingsType',
              webSearchEnabled: variables.webSearchEnabled,
              pastMeetingsSearchEnabled: variables.meetingsLibraryEnabled,
              rangeType: variables.meetingsLibraryRangeType ?? DataRange.LAST_THIRTY_DAYS,
              customFromDate: dateFrom,
              customToDate: dateTo,
            },
          },
        },
      },
    });

    if (!response.data?.manageChatToolSettings?.success) {
      graphErrorHorsemen(response.data?.manageChatToolSettings?.errors);
    }
  };

  const handleChangeBooleanSetting =
    (field: 'web-search' | 'use-library') => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const toolsSettings = data?.chat?.toolsSettings ?? null;
      const webSearchEnabled = toolsSettings?.webSearchEnabled ?? false;
      const meetingsLibraryEnabled = toolsSettings?.pastMeetingsSearchEnabled ?? false;
      const dateFrom = data?.chat?.toolsSettings.customFromDate ?? null;
      const dateTo = data?.chat?.toolsSettings.customToDate ?? null;
      const hasCustomRange = !!dateFrom && !!dateTo;

      handleUpdateSettings({
        chatId: chatId!,
        meetingsLibraryRange: hasCustomRange ? { dateTo, dateFrom } : null,
        meetingsLibraryRangeType: toolsSettings?.rangeType ?? DataRange.LAST_THIRTY_DAYS,
        meetingsLibraryEnabled: field === 'use-library' ? checked : meetingsLibraryEnabled,
        webSearchEnabled: field === 'web-search' ? checked : webSearchEnabled,
      });
    };

  const handleDisableBooleanSetting = (field: 'web-search' | 'use-library') => async () => {
    const toolsSettings = data?.chat?.toolsSettings ?? null;
    const webSearchEnabled = toolsSettings?.webSearchEnabled ?? false;
    const meetingsLibraryEnabled = toolsSettings?.pastMeetingsSearchEnabled ?? false;
    const dateFrom = data?.chat?.toolsSettings.customFromDate ?? null;
    const dateTo = data?.chat?.toolsSettings.customToDate ?? null;
    const hasCustomRange = !!dateFrom && !!dateTo;

    handleUpdateSettings({
      chatId: chatId!,
      meetingsLibraryRange: hasCustomRange ? { dateTo, dateFrom } : null,
      meetingsLibraryRangeType: toolsSettings?.rangeType ?? DataRange.LAST_THIRTY_DAYS,
      meetingsLibraryEnabled: field === 'use-library' ? false : meetingsLibraryEnabled,
      webSearchEnabled: field === 'web-search' ? false : webSearchEnabled,
    });
  };

  const handleChangeRangeType = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const rangeType = event.target.value as DataRange;
    const toolsSettings = data?.chat?.toolsSettings ?? null;
    const webSearchEnabled = toolsSettings?.webSearchEnabled ?? false;
    const meetingsLibraryEnabled = toolsSettings?.pastMeetingsSearchEnabled ?? false;
    const dateFrom =
      toolsSettings?.customFromDate ??
      formatISO(subMonths(new Date(), 1), { representation: 'date' });
    const dateTo = toolsSettings?.customToDate ?? formatISO(new Date(), { representation: 'date' });
    const hasCustomRange = !!dateFrom && !!dateTo;

    handleUpdateSettings({
      chatId: chatId!,
      meetingsLibraryRange: hasCustomRange ? { dateTo, dateFrom } : null,
      meetingsLibraryRangeType: rangeType,
      meetingsLibraryEnabled,
      webSearchEnabled,
    });
  };

  const handleChangeCustomRange =
    (field: 'date-from' | 'date-to') => async (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      if (!value || !isValid(parseISO(value))) return;

      const toolsSettings = data?.chat?.toolsSettings ?? null;
      const webSearchEnabled = toolsSettings?.webSearchEnabled ?? false;
      const meetingsLibraryEnabled = toolsSettings?.pastMeetingsSearchEnabled ?? false;
      const dateFrom =
        field === 'date-from'
          ? value
          : toolsSettings?.customFromDate ??
            formatISO(startOfYear(new Date()), { representation: 'date' });
      let dateTo =
        field === 'date-to'
          ? value
          : toolsSettings?.customToDate ?? formatISO(new Date(), { representation: 'date' });

      // Ensure that the dateTo is always after dateFrom
      if (parseISO(dateTo) <= parseISO(dateFrom)) {
        dateTo = formatISO(addDays(parseISO(dateFrom), 1), { representation: 'date' });
      }

      handleUpdateSettings({
        chatId: chatId!,
        meetingsLibraryRange: { dateTo, dateFrom },
        meetingsLibraryRangeType: toolsSettings?.rangeType ?? DataRange.LAST_THIRTY_DAYS,
        meetingsLibraryEnabled,
        webSearchEnabled,
      });
    };
  /* #endregion */

  /* #region  Render Helpers */
  const dateFrom = data?.chat?.toolsSettings.customFromDate;
  const dateTo = data?.chat?.toolsSettings.customToDate;

  let dateFromISO: string = '';
  let dateToISO: string = '';

  try {
    dateFromISO = dateFrom
      ? formatISO(parseISO(dateFrom), { representation: 'date' })
      : formatISO(startOfYear(new Date()), { representation: 'date' });
  } catch {
    dateFromISO = formatISO(startOfYear(new Date()), { representation: 'date' });
  }

  try {
    dateToISO = dateTo
      ? formatISO(parseISO(dateTo), { representation: 'date' })
      : formatISO(endOfYear(parseISO(dateFromISO)), { representation: 'date' });
  } catch {
    dateToISO = formatISO(endOfYear(parseISO(dateFromISO)), { representation: 'date' });
  }

  // Ensure that the dateTo is always after dateFrom
  const dateToMinISO = formatISO(addDays(parseISO(dateFromISO), 1), { representation: 'date' });
  /* #endregion */

  const renderStatusChips = () => (
    <div className={styles.chips}>
      {!!data?.chat?.toolsSettings.webSearchEnabled && (
        <Chip
          size="small"
          label="Use the Web"
          className={styles.chip}
          onDelete={handleDisableBooleanSetting('web-search')}
        />
      )}
      {!!data?.chat?.toolsSettings.pastMeetingsSearchEnabled && (
        <>
          <Chip
            size="small"
            label="Use my Meeting Library"
            className={styles.chip}
            onDelete={handleDisableBooleanSetting('use-library')}
          />
          {!!data?.chat?.toolsSettings.rangeType && (
            <Chip
              size="small"
              label={
                data?.chat?.toolsSettings.rangeType === DataRange.LAST_SEVEN_DAYS
                  ? 'Last 7 days'
                  : data?.chat?.toolsSettings.rangeType === DataRange.LAST_THIRTY_DAYS
                  ? 'Last 30 days'
                  : data?.chat?.toolsSettings.rangeType === DataRange.LAST_THREE_MONTHS
                  ? 'Last 3 months'
                  : `Custom range: ${dateFromISO} – ${dateToISO}`
              }
              className={styles.chip}
            />
          )}
        </>
      )}
    </div>
  );

  return (
    <div className={styles.root}>
      {/* Begin: AppDrawer placeholder */}
      {/* AppDrawer currenty always open on desktops */}
      {!isSmallScreen && <Box flex="0 1 auto" width={APP_DRAWER_WIDTH} />}
      {/* End: AppDrawer placeholder */}
      <div className={styles.content}>
        <AppBar className={styles.appBar} layout="extra-wide">
          {isSmallScreen && (
            <IconButton onClick={handleDrawerOpen} size="small" className={styles.menuIcon}>
              <MenuIcon />
            </IconButton>
          )}
          <div className={styles.grow}>
            {!!chatTitle && (
              <Typography
                noWrap
                component="div"
                variant={isSmallScreen ? 'body2' : 'body1'}
                className={styles.title}
              >
                {chatTitle}
              </Typography>
            )}
          </div>
          <div className={styles.nogrow}>
            {!!chatId && (
              <>
                {!isSmallScreen && renderStatusChips()}
                <IconButton
                  size="small"
                  className={styles.iconButton}
                  onClick={handleOpenTitleMenu}
                >
                  <SettingsIcon fontSize="small" />
                </IconButton>

                <IconButton
                  size="small"
                  className={styles.iconButton}
                  onClick={handleOpenActionMenu}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </div>
        </AppBar>

        <div className={styles.appBarSpacer} />

        {(!!data?.chat?.toolsSettings.webSearchEnabled ||
          !!data?.chat?.toolsSettings.pastMeetingsSearchEnabled) && (
          <div className={styles.mobileBar}>{isSmallScreen && renderStatusChips()}</div>
        )}

        {children}
      </div>
      {!!actionsAnchorEl && (
        <Menu
          open
          anchorEl={actionsAnchorEl}
          PaperProps={{ square: true, className: styles.menu }}
          onClose={handleCloseActionMenu}
        >
          <MenuItem onClick={handleClickOnRenameChat}>Rename chat</MenuItem>
          <MenuItem onClick={handleDeleteChat}>Delete chat</MenuItem>
        </Menu>
      )}
      {!!titleAnchorEl && (
        <Popover
          open
          anchorEl={titleAnchorEl}
          PaperProps={{ square: true, className: styles.menu }}
          onClose={handleCloseTitleMenu}
        >
          {loading ? (
            <Box py={8} textAlign="center" minWidth={300}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <div className={styles.popover}>
              <Typography gutterBottom variant="body2" color="textSecondary">
                Chat Settings
              </Typography>

              <FormControlLabel
                label="Use the Web"
                labelPlacement="end"
                classes={{ root: styles.formControl, label: styles.label }}
                control={
                  <Switch
                    checked={data?.chat?.toolsSettings.webSearchEnabled ?? false}
                    onChange={handleChangeBooleanSetting('web-search')}
                  />
                }
              />
              <Divider />
              <FormControlLabel
                label="Use my Meeting Library"
                labelPlacement="end"
                classes={{ root: styles.formControl, label: styles.label }}
                control={
                  <Switch
                    checked={data?.chat?.toolsSettings.pastMeetingsSearchEnabled ?? false}
                    onChange={handleChangeBooleanSetting('use-library')}
                  />
                }
              />
              <Box mb={0.25} />
              <Typography component="div" variant="body1">
                <b>Range</b>
              </Typography>

              <FormControl fullWidth size="small" variant="filled">
                <Select
                  disabled={!data?.chat?.toolsSettings.pastMeetingsSearchEnabled}
                  value={data?.chat?.toolsSettings.rangeType ?? DataRange.LAST_THIRTY_DAYS}
                  onChange={handleChangeRangeType}
                >
                  <MenuItem value={DataRange.LAST_SEVEN_DAYS}>Last 7 days</MenuItem>
                  <MenuItem value={DataRange.LAST_THIRTY_DAYS}>Last 30 days</MenuItem>
                  <MenuItem value={DataRange.LAST_THREE_MONTHS}>Last 3 months</MenuItem>
                  <MenuItem value={DataRange.CUSTOM}>Custom range</MenuItem>
                </Select>
              </FormControl>

              {data?.chat?.toolsSettings.rangeType === DataRange.CUSTOM && (
                <>
                  <Typography component="div" variant="body1">
                    <b>From</b>
                  </Typography>
                  <TextField
                    fullWidth
                    type="date"
                    size="small"
                    variant="filled"
                    disabled={!data?.chat?.toolsSettings.pastMeetingsSearchEnabled}
                    value={dateFromISO}
                    onChange={handleChangeCustomRange('date-from')}
                    className={styles.textField}
                  />
                  <Typography component="div" variant="body1">
                    <b>To</b>
                  </Typography>
                  <TextField
                    fullWidth
                    type="date"
                    size="small"
                    variant="filled"
                    InputProps={{ inputProps: { min: dateToMinISO } }}
                    disabled={!data?.chat?.toolsSettings.pastMeetingsSearchEnabled}
                    value={dateToISO}
                    onChange={handleChangeCustomRange('date-to')}
                    className={styles.textField}
                  />
                </>
              )}
            </div>
          )}
        </Popover>
      )}
      {!!isEditingChat && (
        <RenameModalDialog
          title="Rename Chat"
          defaultValue={chatTitle || ''}
          onClose={() => setIsEditingChat(false)}
          onSubmit={handleChangeChatName}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  menuIcon: {
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(1),
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    borderBottom: 'none',
    overflow: 'hidden',
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
  },
  chip: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[200],
  },
  chips: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    overflowX: 'auto',
  },
  grow: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    gap: theme.spacing(1),
  },
  nogrow: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    flexGrow: 0,
    paddingLeft: theme.spacing(2),
  },
  menu: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    lineHeight: 1.75,
    [theme.breakpoints.down('sm')]: {
      lineHeight: 2,
    },
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
  },
  formControl: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: 0,
    gap: theme.spacing(3),
  },
  label: {
    ...theme.typography.subtitle2,
  },
  mobileBar: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(3.5),
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  textField: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
}));

export default ChatPageLayout;
