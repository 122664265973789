import ReactMarkdown, { MarkdownToJSX } from 'markdown-to-jsx';

export interface MarkdownProps {
  options?: MarkdownToJSX.Options;
  children: string & React.ReactNode;
}

/**
 * A wrapper around `markdown-to-jsx` for rendering Markdown content with predefined options.
 * Features include:
 * - Preserving line breaks and preventing layout issues with long words or links.
 * - Enforcing ATX-style headings for Markdown (`# Header` requires a space).
 *
 * Supports customization through the `options` prop to extend or override default settings.
 */
export const Markdown: React.VFC<MarkdownProps> = ({ children, options = {} }) => {
  return (
    <ReactMarkdown
      children={children}
      options={{
        wrapper: ({ children }) => (
          <div
            style={{
              whiteSpace: 'pre-wrap', // Preserve line breaks and prevent text breaking the layout
              wordBreak: 'break-word', // Prevent long words or links from breaking layout
              overflowWrap: 'break-word', // Ensure proper handling of long unbreakable strings
            }}
          >
            {children}
          </div>
        ),
        enforceAtxHeadings: true, // see https://github.github.com/gfm/#atx-heading
        ...options,
      }}
    />
  );
};

export default Markdown;
