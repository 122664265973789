import { copyToClipboard } from '@powowfe/common';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useState, useRef } from 'react';
// Material UI
import ExportIcon from '@material-ui/icons/SaveAlt';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DialogContent from '@material-ui/core/DialogContent';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
import CopyIcon from '@material-ui/icons/FileCopy';
// Lib Shared
import { GenericDialog, Markdown } from '../components';
// GraphQL Queries and Types
import exportMutation from '../graphql/mutations/ExportArtifact.graphql';
import { ExportArtifact, ExportArtifactVariables, OutputFormatEnum } from '../types';

export interface ChatTextArtefactViewerProps {
  id: string;
  title: string;
  data: string;
  onClose: () => void;
}

const EXPORT_OPTIONS = [
  { label: 'Export to PDF', value: OutputFormatEnum.PDF },
  { label: 'Export to DOCX', value: OutputFormatEnum.DOCX },
  { label: 'Export to HTML', value: OutputFormatEnum.HTML },
  { label: 'Export to Markdown', value: OutputFormatEnum.MARKDOWN },
];

export const ChatTextArtefactViewer: React.VFC<ChatTextArtefactViewerProps> = ({
  id,
  title,
  data,
  onClose,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [exportToPDF, { loading: isExporting }] = useMutation<
    ExportArtifact,
    ExportArtifactVariables
  >(exportMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleClick = async () => {
    const format = EXPORT_OPTIONS[selectedIndex].value;

    const result = await exportToPDF({
      variables: { format, answerFileId: id },
    });
    if (!result.data?.exportArtifact?.link) {
      toast.error('Failed to export the file. Please try again later.');
    } else {
      // download the file
      window.open(result.data.exportArtifact.link, '_blank');
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleCopyContent = async () => {
    const success = await copyToClipboard(data);
    if (success) toast.dark('Copied to clipboard.');
  };
  /* #endregion */

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ fullWidth: true, maxWidth: 'md', fullScreen: isSmallScreen }}
      onClose={onClose}
    >
      <Box component={DialogContent} mb={4}>
        <div className={styles.head}>
          <Box flex={1}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Toolbar disableGutters className={styles.toolbar}>
          <Button
            color="default"
            variant="outlined"
            startIcon={<CopyIcon fontSize="small" color="primary" />}
            onClick={handleCopyContent}
          >
            <Typography noWrap component="span" variant="body1">
              Copy content
            </Typography>
          </Button>
          <ButtonGroup color="default" variant="outlined" ref={anchorRef}>
            <Button
              startIcon={<ExportIcon fontSize="small" color="primary" />}
              endIcon={isExporting ? <CircularProgress size={16} color="inherit" /> : null}
              onClick={handleClick}
            >
              <Typography noWrap component="span" variant="body1">
                {EXPORT_OPTIONS[selectedIndex].label}
              </Typography>
            </Button>
            <Button
              size="small"
              color="default"
              aria-expanded={open ? 'true' : undefined}
              aria-label="Select file format"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {EXPORT_OPTIONS.map((option, index) => (
                        <MenuItem
                          key={option.value}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
        <Box my={2}>
          <Markdown children={data} />
        </Box>
      </Box>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  toolbar: {
    top: 0,
    position: 'sticky',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    gap: theme.spacing(1),
    zIndex: 1,
  },
}));

export default ChatTextArtefactViewer;
