import { useState } from 'react';
import { NetworkStatus, useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import ClearIcon from '@material-ui/icons/Clear';
import HistoryIcon from '@material-ui/icons/History';
import TuneIcon from '@material-ui/icons/Tune';
// Lib Shared
import AdvancedSearchDialog from '../dialogs/AdvancedSearchDialog';
import searchImage from '../assets/icon-search-36.svg';
import SearchInput from '../components/SearchInput';
import SearchResults from '../components/SearchResults';
// GraphQL Queries and Types
import clearAllMutation from '../graphql/mutations/ClearAllRecentSearches.graphql';
import clearItemMutation from '../graphql/mutations/ClearRecentSearchItem.graphql';
import searchHistoryQuery from '../graphql/queries/AdvancedSearchHistoryItems.graphql';
import searchQuery from '../graphql/queries/MeetingsSearch.graphql';
import {
  AdvancedSearchHistoryItems,
  AdvancedSearchHistoryItemsVariables,
  ClearAllRecentSearches,
  ClearRecentSearchItem,
  ClearRecentSearchItemVariables,
  MeetingsSearch,
  MeetingsSearchVariables,
  MeetingStatuses,
} from '../types';

export interface SearchBoxContainerProps {
  placeholder?: string;
  isSmartMeetingUser: boolean;
  onSearchComplete?: (search: string) => void;
  onLoadMoreSearchResults?: () => void;
  onClickOnSearchResultCard: (id: string) => void;
}

export const SearchBoxContainer: React.VFC<SearchBoxContainerProps> = ({
  placeholder,
  isSmartMeetingUser,
  onSearchComplete = () => null,
  onLoadMoreSearchResults = () => null,
  onClickOnSearchResultCard,
}) => {
  const styles = useStyles();

  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [deletedHistoryItems, setDeletedHistoryItems] = useState<string[]>([]);

  const {
    data: searchHistoryData,
    refetch: refetchSearchHistory,
    networkStatus: searchHistoryNetworkStatus,
  } = useQuery<AdvancedSearchHistoryItems, AdvancedSearchHistoryItemsVariables>(
    searchHistoryQuery,
    { variables: { limit: 6 }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true },
  );

  const [find, { fetchMore, refetch, data, called, networkStatus }] = useLazyQuery<
    MeetingsSearch,
    MeetingsSearchVariables
  >(searchQuery, { notifyOnNetworkStatusChange: true });

  const [clearAllRecentSearches, { loading: isClearingAll }] =
    useMutation<ClearAllRecentSearches>(clearAllMutation);

  const [clearRecentSearchItem] = useMutation<
    ClearRecentSearchItem,
    ClearRecentSearchItemVariables
  >(clearItemMutation);

  /* #region  Handlers */
  const handleRequestNextSearchPage = () => {
    if (fetchMore && data?.meetingsPaginated?.page) {
      fetchMore({ variables: { page: data.meetingsPaginated.page + 1 } });
      onLoadMoreSearchResults();
    }
  };

  const handleSearch = async (searchValue: string, page = 1, perPage = 6) => {
    const search = searchValue ? searchValue.toLowerCase() : '';

    if (search && called && refetch) {
      await refetch({ search, page, perPage, statuses: [MeetingStatuses.submitted] });
    } else {
      await find({
        variables: {
          search,
          page,
          perPage,
          statuses: [MeetingStatuses.submitted],
        },
      });
    }

    refetchSearchHistory();
    if (!!search) onSearchComplete(search);
  };

  const handleSearchDebounced = useDebouncedCallback((value?: string) => {
    handleSearch(value || searchTerm);
  }, 1000);

  const handleChangeSearchTerm = (value: string) => {
    setSearchTerm(value);
    handleSearchDebounced(value);
  };

  const handleClickOnResult = (id: string) => {
    setIsFocused(false);
    setIsAdvancedSearchOpen(false);
    onClickOnSearchResultCard(id);
  };

  const handleSetFocus = () => {
    setIsFocused(true);
  };

  const handleClickAway = () => {
    setIsFocused(false);
  };

  const handleToggleAdvancedSearch = (open: boolean) => () => {
    setIsAdvancedSearchOpen(open);
    setIsFocused(false);
  };

  const handleSearchHistoryClick = (search: string) => () => {
    setSearchTerm(search);
    handleSearch(search);
  };

  const handleClickClearAllRecentSearches = async () => {
    await clearAllRecentSearches();
    refetchSearchHistory();
  };

  const handleClickClearRecentSearchItem = (id: string) => () => {
    deletedHistoryItems.push(id);
    clearRecentSearchItem({
      variables: { id: +id },
      optimisticResponse: {
        clearRecentSearchItem: {
          __typename: 'ClearRecentSearchItemMutationPayload',
          success: true,
          errors: null,
        },
      },
      onCompleted: (result) => {
        if (result.clearRecentSearchItem?.success) {
          refetchSearchHistory();
        } else {
          setDeletedHistoryItems(deletedHistoryItems.filter((item) => item !== id));
        }
      },
    });
  };
  /* #endregion */

  /* #region  Render Helpers */
  const fetchingStatuses = [
    NetworkStatus.loading,
    NetworkStatus.setVariables,
    NetworkStatus.fetchMore,
    NetworkStatus.refetch,
  ];

  const isFetching = fetchingStatuses.includes(networkStatus);
  const hasMoreSearchResults = data?.meetingsPaginated?.hasNext ?? false;
  const isLoadingHistoryData = searchHistoryNetworkStatus === NetworkStatus.loading;
  const isRefetchingHistoryData = searchHistoryNetworkStatus === NetworkStatus.refetch;

  const historyDataObjects = searchHistoryData?.meetingsSearchHistory || null;
  const historyData = !!historyDataObjects
    ? historyDataObjects.filter((item) => !deletedHistoryItems.includes(item.id))
    : null;

  const hasSearchTerm = !!searchTerm && called;
  /* #endregion */

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.wrapper}>
          <div className={`${styles.root} ${isFocused ? styles.rootFocused : ''}`}>
            <SearchInput
              className={isFocused ? styles.inputExpanded : styles.inputCollapsed}
              isFetching={isFetching}
              placeholder={placeholder}
              value={searchTerm}
              onClick={handleSetFocus}
              onChange={handleChangeSearchTerm}
            />

            {!isFocused && !searchTerm && (
              <Tooltip arrow title="Advanced Search">
                <IconButton className={styles.addon} onClick={handleToggleAdvancedSearch(true)}>
                  <TuneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            {isFocused && (
              <>
                <Divider />
                {hasSearchTerm ? (
                  <>
                    {!data?.meetingsPaginated?.objects?.length ? (
                      <div className={styles.container}>
                        {!isFetching && (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            gridGap={18}
                            m={2}
                            py={8}
                          >
                            <img width={36} alt="Search" src={searchImage} />
                            <div>
                              <Typography gutterBottom variant="h6" align="center">
                                Nothing found
                              </Typography>
                              <Typography variant="body1" align="center">
                                You may want to try using different keywords, checking for typos, or
                                adjusting your filters.
                              </Typography>
                            </div>
                          </Box>
                        )}
                      </div>
                    ) : (
                      <SearchResults
                        className={styles.container}
                        data={data.meetingsPaginated.objects}
                        hasNextPage={hasMoreSearchResults}
                        isFetchingMore={networkStatus === NetworkStatus.fetchMore}
                        onClickOnItem={handleClickOnResult}
                        onClickOnNextPage={handleRequestNextSearchPage}
                      />
                    )}
                  </>
                ) : (
                  <div className={styles.container}>
                    {isLoadingHistoryData ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        m={2}
                        py={8}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        {historyData?.length ? (
                          <List
                            subheader={
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                gridGap={16}
                                pr={1}
                              >
                                <ListSubheader>
                                  <Box display="flex" alignItems="center" gridGap={16}>
                                    <span>Recent searches</span>
                                    {isRefetchingHistoryData && <CircularProgress size={16} />}
                                  </Box>
                                </ListSubheader>
                                <Button
                                  size="small"
                                  color="default"
                                  disabled={isClearingAll}
                                  endIcon={isClearingAll && <CircularProgress size={16} />}
                                  onClick={handleClickClearAllRecentSearches}
                                >
                                  <Box component="span" px={1}>
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      Clear all
                                    </Typography>
                                  </Box>
                                </Button>
                              </Box>
                            }
                          >
                            {historyData.map((item) => (
                              <ListItem
                                button
                                key={item.id}
                                classes={{ container: styles.listItem }}
                                onClick={handleSearchHistoryClick(item.search)}
                              >
                                <ListItemIcon>
                                  <HistoryIcon />
                                </ListItemIcon>
                                <ListItemText primary={item.search} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    size="small"
                                    title="Delete this item from your search history"
                                    className="ghostly"
                                    onClick={handleClickClearRecentSearchItem(item.id)}
                                  >
                                    <ClearIcon fontSize="small" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            gridGap={18}
                            m={2}
                            py={8}
                          >
                            <img width={36} alt="Search" src={searchImage} />
                            <div>
                              <Typography gutterBottom variant="h6" align="center">
                                No recent items available
                              </Typography>
                              <Typography variant="body1" align="center">
                                Try typing to see suggestions
                              </Typography>
                            </div>
                          </Box>
                        )}
                      </>
                    )}
                  </div>
                )}
                <Box bgcolor="grey.50" textAlign="right" p={2}>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<TuneIcon color="action" />}
                    onClick={handleToggleAdvancedSearch(true)}
                  >
                    Advanced Search
                  </Button>
                </Box>
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
      <AdvancedSearchDialog
        open={isAdvancedSearchOpen}
        isSmartMeetingUser={isSmartMeetingUser}
        onClose={handleToggleAdvancedSearch(false)}
        onClickOnMeetingCard={handleClickOnResult}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: theme.breakpoints.values.md,
    height: 40,
  },
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
    borderRadius: '2em',
    transition: 'box-shadow 100ms',
  },
  rootFocused: {
    boxShadow: theme.shadows[15],
  },
  addon: {
    position: 'absolute',
    padding: theme.spacing(0.75),
    right: theme.spacing(0.75),
  },
  container: {
    flex: 1,
    top: 1,
    left: 0,
    width: '100%',
    overflow: 'auto',
    maxHeight: '80vh',
    marginBottom: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  inputCollapsed: {
    transition: 'all 100ms',
  },
  inputExpanded: {
    boxShadow: theme.shadows[0],
    borderColor: 'transparent',
    borderRadius: 0,
    backgroundColor: 'inherit',
  },
  listItem: {
    '& .ghostly': {
      visibility: 'hidden',
      [theme.breakpoints.down('xs')]: {
        visibility: 'visible',
      },
    },
    '&:hover .ghostly': {
      visibility: 'visible',
    },
  },
}));

export default SearchBoxContainer;
