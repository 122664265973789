import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
// GraphQL
import calendarIntegrationsProcessingStatusQuery from '@shared/queries/CalendarIntegrationsProcessingStatus.graphql';
import googleCalendarsQuery from '@shared/queries/GoogleCalendars.graphql';
import userIntegrationsQuery from '@shared/queries/UserIntegrations.graphql';
import { CalendarIntegrationsProcessingStatus } from '@gql-types/CalendarIntegrationsProcessingStatus';
import { GoogleCalendars as GoogleCalendarsQuery } from '@gql-types/GoogleCalendars';
import { IntegrationTypes } from '@gql-types/globalTypes';
import { UserIntegrations } from '@gql-types/UserIntegrations';
// App Shared
import { apolloClient } from '@shared/clients/ApolloClient';
import { useUserContext } from '@shared/hooks';

export function useCalendarsSyncStatus(): [boolean | null, () => void] {
  /* #region  Hooks */
  const user = useUserContext();

  const [isSyncing, setIsSyncing] = useState<null | boolean>(null);

  const [startPolling, { data, stopPolling }] = useLazyQuery<CalendarIntegrationsProcessingStatus>(
    calendarIntegrationsProcessingStatusQuery,
    {
      fetchPolicy: 'network-only',
      pollInterval: 2000,
    },
  );
  /* #endregion */

  const handleStopSync = () => {
    setIsSyncing(null);
  };

  /* #region  Effects */
  useEffect(() => {
    if (user.data?.me?.isCalendarIntegrationsProcessing) {
      setIsSyncing(true);
      startPolling();
    }
  }, [startPolling, user.data?.me?.isCalendarIntegrationsProcessing]);

  useEffect(() => {
    if (data?.me?.isCalendarIntegrationsProcessing === false) {
      stopPolling();
      setIsSyncing(false);

      toast.dark('Calendar successfully synced.');

      (async () => {
        apolloClient.refetchQueries({
          updateCache(cache) {
            cache.modify({
              fields: {
                myMeetingsPaginated(value, { DELETE }) {
                  return DELETE;
                },
              },
            });
          },
        });

        const response = await apolloClient.query<UserIntegrations>({
          query: userIntegrationsQuery,
          fetchPolicy: 'network-only',
        });

        const isGoogleCalendarsIntegrationConnected = response.data.userIntegrations?.find(
          (item) => item.type === IntegrationTypes.GOOGLE_CALENDAR_USER,
        );

        if (isGoogleCalendarsIntegrationConnected) {
          await apolloClient.query<GoogleCalendarsQuery>({
            query: googleCalendarsQuery,
            fetchPolicy: 'network-only',
          });
        }
      })();
    }
  }, [data?.me?.isCalendarIntegrationsProcessing, stopPolling]);
  /* #endregion */

  return [isSyncing, handleStopSync];
}

export default useCalendarsSyncStatus;
