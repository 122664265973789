import { useEffect } from 'react';
import { getCookie, setCookie } from 'typescript-cookie';
// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Sembly UI
import { GenericDialog } from '@sembly-ui';
// App Shared
import backgroundImage from '@shared/assets/welcome-bg.png';
import HandWaveIcon from '@shared/icons/HandWave';
import logo from '@shared/assets/full-white-logo-sembly.svg';
import { useUserContext } from '@shared/hooks';
import { ga } from '@shared/utils';

export interface UserOnboardingModalProps {
  open: boolean;
  onGetStarted: () => void;
}

export const UserOnboardingModal: React.VFC<UserOnboardingModalProps> = ({
  open,
  onGetStarted,
}) => {
  const styles = useStyles();
  const user = useUserContext();
  const planId = user.data?.me?.paymentCustomer?.plan?.id;

  useEffect(() => {
    if (!open || !planId) return;
    const COOKIE_NAME = 'sembly_ak_registration_completed';
    const isNotified = getCookie(COOKIE_NAME);
    if (!isNotified) {
      setCookie(COOKIE_NAME, 'True', { expires: 365 * 100 });
      ga.event({
        category: 'User',
        action: 'AK_RegistrationCompleted',
        label: planId,
      });
    }
  }, [open, planId]);

  return (
    <GenericDialog
      hideTitle
      dialogProps={{
        open,
        fullScreen: true,
        fullWidth: true,
        maxWidth: 'md',
      }}
      onClose={onGetStarted}
    >
      <div className={styles.root}>
        <Typography className={styles.title} color="inherit">
          Welcome to Sembly!
          <HandWaveIcon />
        </Typography>
        <Button
          disableElevation
          variant="contained"
          color="inherit"
          className={styles.button}
          aria-label="Get started"
          onClick={onGetStarted}
        >
          Get started
        </Button>

        <img className={styles.logo} src={logo} alt="Semlby AI logo" />
      </div>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.black,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.common.white,
  },
  title: {
    fontSize: 64,
    '& svg': {
      marginLeft: theme.spacing(1),
      fontSize: 48,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h3.fontSize,
      '& svg': {
        fontSize: 23,
      },
    },
  },
  button: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.common.black,
  },
  logo: {
    top: theme.spacing(2),
    left: theme.spacing(2),
    position: 'absolute',
  },
}));

export default UserOnboardingModal;
