import { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { format } from 'date-fns';
// Material UI
import Link from '@material-ui/core/Link';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
import Typography from '@material-ui/core/Typography';
import { Box, CircularProgress, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// Lib Queries
import editDecisionMutation from '../graphql/mutations/EditDecision.graphql';
import editIssueMutation from '../graphql/mutations/EditIssue.graphql';
import editKeyEventMutation from '../graphql/mutations/EditKeyEvent.graphql';
import editNoteworthyDetailMutation from '../graphql/mutations/EditNoteworthyDetail.graphql';
import editParkingLotMutation from '../graphql/mutations/EditParkingLot.graphql';
import editQAndAMutation from '../graphql/mutations/EditQAndA.graphql';
import editRequirementMutation from '../graphql/mutations/EditRequirement.graphql';
import editRiskMutation from '../graphql/mutations/EditRisk.graphql';
import genericKeyEventFragment from '../graphql/fragments/GenericKeyEvent.graphql';
import keyItemsQuery from '../graphql/queries/MeetingKeyItems.graphql';
import meetingQuery from '../graphql/queries/Meeting.graphql';
// Lib Generated Types
import {
  AgentCallPlatform,
  EditDecision,
  EditDecisionVariables,
  EditIssue,
  EditIssueVariables,
  EditKeyEvent,
  EditKeyEventVariables,
  EditNoteworthyDetail,
  EditNoteworthyDetailVariables,
  EditParkingLot,
  EditParkingLotVariables,
  EditQAndA,
  EditQAndAVariables,
  EditRequirement,
  EditRequirementVariables,
  EditRisk,
  EditRiskVariables,
  GenericKeyEvent,
  Meeting,
  MeetingKeyItems,
  MeetingKeyItemsVariables,
  MeetingStatuses,
  MeetingVariables,
  NumKeyItems,
} from '../types';
// Lib Components
import { ActionDueDateMenu } from '../components/ActionDueDateMenu';
import { KeyItemIntegrationsMenuContainer } from './KeyItemIntegrationsMenuContainer';
import { KeyItemMenuDesktop } from '../components/KeyItemMenuDesktop';
import { KeyItemMenuMobile } from '../components/KeyItemMenuMobile';
import { KeyItemsView } from '../components/KeyItemsView';
import { MeetingPlaceholderKeyItems } from '../components/MeetingPlaceholderKeyItems';
import { MeetingPlaceholderProcessing } from '../components/MeetingPlaceholderProcessing';
// Lib Shared
import KeyItemsGroupContainer, { KeyItemsGroupContainerMenuData } from './KeyItemsGroupContainer';
import { ACTIVITY_TYPES } from '../constants';
import { ActivityType, GraphError, GenericKeyItem, RedirectTarget } from '../types';
import { usePlayer } from '../hooks';

/* #region  Types */
type EditVariables =
  | EditDecisionVariables
  | EditIssueVariables
  | EditKeyEventVariables
  | EditParkingLotVariables
  | EditQAndAVariables
  | EditRequirementVariables
  | EditRiskVariables
  | EditNoteworthyDetailVariables;

type ActivityKey = keyof typeof ACTIVITY_TYPES;

type KeyItemContextMenuState = {
  anchorEl: HTMLElement;
  keyItem: GenericKeyItem;
  keyItemType: ActivityType;
} | null;

type ActionDueDateState = {
  anchorEl: HTMLElement;
  keyItemId: string;
  keyItemType: ActivityKey;
  dueDate: string | Date | null;
} | null;

type IntegrationsMenuState = {
  anchorEl: HTMLElement;
  keyItemId: string;
  keyItemType: ActivityKey;
} | null;

type ScrollTarget = {
  keyItemId: string;
  keyItemType: string;
};

export interface KeyItemsContainerProps {
  isAuthorizedToView: boolean;
  isPromoteUpgrade?: boolean;
  isRestrictedKeyItemsPromotion: boolean;
  meetingId: string;
  restrict3rdPartyIntegrations?: boolean;
  scrollTarget?: ScrollTarget | null;
  onClickOnCopyKeyItemContent: (keyItemId: string, keyItemType: ActivityType, text: string) => void;
  onClickOnCopyKeyItemLink: (keyItemId: string, keyItemType: ActivityType) => void;
  onClickOnDiscoverIntegrations?: () => void;
  onClickOnTranscriptLink: (diarizationItemId: string) => void;
  onClickOnUpgradePlan?: () => void;
  onPostingSuccess?: () => void;
  onRedirect: (target: RedirectTarget) => void;
  onResponseError?: (error: GraphError) => void;
  onUpdateKeyItemError?: (error: GraphError) => void;
  onUpdateKeyItemSuccess?: () => void;
}
/* #endregion */

/**
 * Container that renders the meeting key items with all the actions
 */
export const KeyItemsContainer: React.VFC<KeyItemsContainerProps> = ({
  isAuthorizedToView,
  isPromoteUpgrade = false,
  isRestrictedKeyItemsPromotion,
  meetingId,
  restrict3rdPartyIntegrations = false,
  scrollTarget = null,
  onClickOnCopyKeyItemContent,
  onClickOnCopyKeyItemLink,
  onClickOnDiscoverIntegrations = () => null,
  onClickOnTranscriptLink,
  onClickOnUpgradePlan = () => null,
  onPostingSuccess = () => null,
  onRedirect,
  onResponseError = () => null,
  onUpdateKeyItemError = () => null,
  onUpdateKeyItemSuccess = () => null,
}) => {
  /* #region  Hooks */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const client = useApolloClient();
  const diarizationPlayer = usePlayer();

  const [isKeyItemsRefetched, setIsKeyItemsRefetched] = useState(false);
  const [isRefetchingKeyItems, setIsRefetchingKeyItems] = useState(false);
  const [integrationMenuData, setIntegrationMenuData] = useState<IntegrationsMenuState>(null);
  const [changingDueDate, setChangingDueDate] = useState<ActionDueDateState>(null);
  const [mobileMenuData, setMobileMenuData] = useState<KeyItemContextMenuState>(null);
  const [desktopMenuData, setDesktopMenuData] = useState<KeyItemsGroupContainerMenuData | null>(null); // prettier-ignore

  const {
    data: keyItemsData,
    loading: isKeyItemsLoading,
    refetch: refetchKeyItems,
  } = useQuery<MeetingKeyItems, MeetingKeyItemsVariables>(keyItemsQuery, {
    variables: { meetingId },
    skip: !isAuthorizedToView,
  });

  const { data: meetingData, loading: isMeetingLoading } = useQuery<Meeting, MeetingVariables>(
    meetingQuery,
    { variables: { meetingId } },
  );

  const [editDecision] = useMutation<EditDecision, EditDecisionVariables>(editDecisionMutation); // prettier-ignore
  const [editIssue] = useMutation<EditIssue, EditIssueVariables>(editIssueMutation); // prettier-ignore
  const [editKeyEvent] = useMutation<EditKeyEvent, EditKeyEventVariables>(editKeyEventMutation); // prettier-ignore
  const [editNoteworthyDetail] = useMutation<EditNoteworthyDetail, EditNoteworthyDetailVariables>(editNoteworthyDetailMutation); // prettier-ignore
  const [editParkingLot] = useMutation<EditParkingLot, EditParkingLotVariables>(editParkingLotMutation); // prettier-ignore
  const [editQAndA] = useMutation<EditQAndA, EditQAndAVariables>(editQAndAMutation); // prettier-ignore
  const [editRequirement] = useMutation<EditRequirement, EditRequirementVariables>(editRequirementMutation); // prettier-ignore
  const [editRisk] = useMutation<EditRisk, EditRiskVariables>(editRiskMutation); // prettier-ignore
  /* #endregion */

  /* #region  Utils */
  const updateKeyEventDueDate = async (id: string, dueDate: Date | string | null) => {
    const cachedData: GenericKeyEvent | null = client.readFragment({
      id: `KeyEventType:${id}`,
      fragment: genericKeyEventFragment,
      fragmentName: 'GenericKeyEvent',
    });

    if (!cachedData) {
      onResponseError('Cannot find the selected item');
      return;
    }

    const isCustomDate = dueDate !== null && typeof dueDate === 'string';
    const isDataObject = dueDate !== null && typeof dueDate === 'object';

    const { data } = await editKeyEvent({
      variables: {
        id: +id,
        customText: cachedData.customText || '',
        diarizationItem: cachedData.diarizationItem?.id,
        isActive: cachedData.isActive,
        rawTiming: isCustomDate ? dueDate : null,
        dueDate: isDataObject ? format(dueDate, 'yyyy-MM-dd') : null,
      },
      optimisticResponse: {
        editKeyEvent: {
          __typename: 'EditKeyEventMutationPayload',
          success: true,
          errors: [],
          keyEvent: {
            ...cachedData,
            rawTiming: isCustomDate ? dueDate : null,
            dueDate: isDataObject ? format(dueDate, 'yyyy-MM-dd') : null,
          },
        },
      },
    });

    if (!data?.editKeyEvent?.success) {
      onResponseError(data?.editKeyEvent?.errors);
    } else {
      onUpdateKeyItemSuccess();
    }
  };
  /* #endregion */

  /* #region  Handlers */
  const handleOpenMobileMenu = (data: KeyItemsGroupContainerMenuData) => {
    setMobileMenuData(data);
  };

  const handleCloseMobileMenu = () => {
    setMobileMenuData(null);
  };

  const handleToggleDesktopMenu = (data: KeyItemsGroupContainerMenuData | null) => {
    setDesktopMenuData(data);
  };

  const handleOpenDueDateChangeMenu =
    (keyItemType: ActivityKey) =>
    (data: { anchorEl: HTMLElement; keyItemId: string; dueDate: string | Date | null }) => {
      setChangingDueDate({
        keyItemType,
        anchorEl: data.anchorEl,
        keyItemId: data.keyItemId,
        dueDate: data.dueDate,
      });
    };

  const handleCloseDueDateChangeMenu = () => {
    setChangingDueDate(null);
  };

  const handleCloseIntegrationsMenu = () => {
    setIntegrationMenuData(null);
  };

  const handleRewindToTimestamp = (value: number) => {
    if (!diarizationPlayer?.audio.current) return;
    diarizationPlayer.audio.current.currentTime = value;
    diarizationPlayer.audio.current.play();
  };

  const handleOnClickOnCopyKeyItemContent = (data: NonNullable<KeyItemContextMenuState>) => () => {
    const keyItemId = data.keyItem.id;
    const keyItemType = data.keyItemType;
    const text = data.keyItem.customText || data.keyItem.text || '';
    onClickOnCopyKeyItemContent(keyItemId, keyItemType, text);
  };

  const handleOnClickOnCopyKeyItemLink = (data: NonNullable<KeyItemContextMenuState>) => () => {
    const keyItemId = data.keyItem.id;
    const keyItemType = data.keyItemType;
    onClickOnCopyKeyItemLink(keyItemId, keyItemType);
  };

  const handleClickOnTranscriptLink = (data: NonNullable<KeyItemContextMenuState>) => () => {
    if (!data.keyItem.diarizationItem?.id) return;
    onClickOnTranscriptLink(data.keyItem.diarizationItem.id);
  };

  const handleChangeActionText = (
    customText: string,
    entity: GenericKeyItem,
    type: ActivityType,
  ) => {
    const cachedData: GenericKeyItem | null = client.readFragment({
      id: client.cache.identify({ id: entity.id, __typename: entity.__typename }),
      fragment: ACTIVITY_TYPES[type]?.fragment,
      fragmentName: ACTIVITY_TYPES[type]?.fragmentName,
    });

    if (!cachedData) return;

    handleUpdateKeyItem({
      entity,
      type,
      customText,
      isActive: cachedData.isActive,
    });
  };

  const handleToggleActivity =
    (data: NonNullable<KeyItemContextMenuState>) => (isActive?: boolean) => {
      const entity = data.keyItem;
      const type = data.keyItemType;

      const cachedData: GenericKeyItem | null = client.readFragment({
        id: client.cache.identify({ id: entity.id, __typename: entity.__typename }),
        fragment: ACTIVITY_TYPES[type]?.fragment,
        fragmentName: ACTIVITY_TYPES[type]?.fragmentName,
      });

      if (!cachedData) return;

      const customText = cachedData.customText || '';
      handleUpdateKeyItem({ entity, type, customText, isActive });
    };

  const handleChangeDueDate =
    (type: ActivityKey) => (id: string, dueDate: Date | string | null) => {
      if (type === 'keyEvents') {
        updateKeyEventDueDate(id, dueDate);
      }
    };

  const handleUpdateKeyItem = async ({
    entity,
    type,
    customText,
    isActive,
  }: {
    entity: GenericKeyItem;
    type: ActivityType;
    customText: string;
    isActive?: boolean;
  }) => {
    const id = parseInt(entity.id, 10);

    let result;
    let variables: EditVariables = { id, isActive, customText };

    const isEventType = type === 'keyEvents' && entity.__typename === 'KeyEventType';

    if (entity.diarizationItem?.id) {
      variables.diarizationItem = entity.diarizationItem.id;
    }

    if (isEventType) {
      const cachedData: GenericKeyEvent | null = client.readFragment({
        id: client.cache.identify({ id, __typename: entity.__typename }),
        fragment: ACTIVITY_TYPES[type]?.fragment,
        fragmentName: ACTIVITY_TYPES[type]?.fragmentName,
      });

      if (!cachedData) return;

      if (isEventType) {
        (variables as EditKeyEventVariables).dueDate = cachedData.dueDate;
        (variables as EditKeyEventVariables).rawTiming = entity.rawTiming;
      }
    }

    // prettier-ignore
    switch (type) {
      case 'decisions':         result = (await editDecision({ variables })).data?.editDecision; break;
      case 'issues':            result = (await editIssue({ variables })).data?.editIssue; break;
      case 'keyEvents':         result = (await editKeyEvent({ variables })).data?.editKeyEvent; break;
      case 'noteworthyDetails': result = (await editNoteworthyDetail({ variables })).data?.editNoteworthyDetail; break;
      case 'parkingLots':       result = (await editParkingLot({ variables })).data?.editParkingLot; break;
      case 'qAndAs':            result = (await editQAndA({ variables })).data?.editQAndA; break;
      case 'requirements':      result = (await editRequirement({ variables })).data?.editRequirement; break;
      case 'risks':             result = (await editRisk({ variables })).data?.editRisk; break;
    }

    if (result?.success) {
      refetchKeyItems();
      onUpdateKeyItemSuccess();
    } else {
      onUpdateKeyItemError(result?.errors);
    }
  };
  /* #endregion */

  /* #region  Render Helpers */
  const meeting = meetingData?.meeting;
  const sections = Object.keys(ACTIVITY_TYPES) as Array<ActivityType>;
  const navItems = getNavItems(keyItemsData?.meeting?.numKeyItems);
  const agentCall = meeting?.agentCall;
  const keyItems = keyItemsData?.meeting;
  const hasKeyItems = meetingData?.meeting?.hasKeyItems;
  const processingResults = meeting?.processingResults;
  const isTranscribed = processingResults?.processedTranscribing ?? false;
  const isProcessedAssignments = processingResults?.processedAssignments ?? false;
  const isProcessedAnalytics = meeting?.processingResults?.processedAnalytics ?? false;
  const isProcessedNotes = meeting?.processingResults?.processedMeetingNotes ?? false;
  const isProcessingComplete = meeting?.status !== MeetingStatuses.processing;
  const isProcessed = isProcessingComplete || isProcessedAnalytics;
  const isManualUpload = agentCall?.platform === AgentCallPlatform.MANUAL_UPLOAD;
  /* #endregion */

  /* #region  Effects */
  // Refetching the meeting key items when the meeting is
  // completly processed and there are key items available
  useEffect(() => {
    if (isProcessedAnalytics && hasKeyItems && !isKeyItemsRefetched) {
      setIsKeyItemsRefetched(true);
      setIsRefetchingKeyItems(true);
      refetchKeyItems().then(() => setIsRefetchingKeyItems(false));
    }
  }, [isProcessedAnalytics, hasKeyItems, isKeyItemsRefetched, refetchKeyItems]);

  // Closing the menus when the viewport is changed
  useEffect(() => {
    setDesktopMenuData(null);
    setMobileMenuData(null);
  }, [isSmallScreen]);
  /* #endregion */

  /* #region  Props Validation */
  if (isPromoteUpgrade && !onClickOnUpgradePlan) {
    throw new Error('If promotion upgrade is true, handler is required');
  }
  /* #endregion */

  if (isMeetingLoading || isKeyItemsLoading || isRefetchingKeyItems) {
    return (
      <Box position="relative" width="100%" paddingTop={4}>
        <Box textAlign="center" mt={8}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (!meeting) return null;

  return (
    <>
      {!!navItems.length && keyItems ? (
        <>
          <Box mt={4} mb={2}>
            <Typography variant="h4">
              Key Items{' '}
              <Link
                color="textSecondary"
                target="_blank"
                rel="noopener noreferrer"
                title="Help"
                href="https://helpdesk.sembly.ai/hc/en-us/articles/4411177490321-Key-Items-"
                style={{ display: 'inline-block', lineHeight: '1rem', fontSize: '1rem' }}
              >
                <QuestionMarkIcon fontSize="inherit" />
              </Link>
            </Typography>
          </Box>
          <KeyItemsView navItems={navItems}>
            {sections.map((type) => {
              const { keyItemsInitial, numKeyItems } = keyItems;
              const { countKey } = ACTIVITY_TYPES[type];
              const initialKeyItems = keyItemsInitial?.[type] || [];
              const initialActivitiesNumber = numKeyItems[countKey];
              const hasScrollTarget = type === scrollTarget?.keyItemType;
              const scrollTargetId = hasScrollTarget ? scrollTarget?.keyItemId : null;

              return !initialKeyItems || !initialActivitiesNumber ? null : (
                <KeyItemsGroupContainer
                  gridItemProps={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  initialKeyItems={initialKeyItems}
                  isAuthorizedToEdit={meeting.permissions.canManage}
                  isAuthorizedToExport={meeting.permissions.canExport}
                  key={type}
                  keyItemsType={type}
                  meetingId={meetingId}
                  numberOfActivities={initialActivitiesNumber}
                  scrollTargetId={scrollTargetId}
                  onChangeDueDate={handleOpenDueDateChangeMenu(type)}
                  onChangeActionText={handleChangeActionText}
                  onClickOnTimestamp={handleRewindToTimestamp}
                  onOpenMobileMenu={handleOpenMobileMenu}
                  onToggleDesktopMenu={handleToggleDesktopMenu}
                />
              );
            })}
          </KeyItemsView>
        </>
      ) : (
        <>
          {isProcessed ? (
            // Meeting is processed but there are no key items
            <MeetingPlaceholderKeyItems
              failureReason={agentCall?.failureReason || null}
              isManualRecording={isManualUpload}
              isProcessedAssignments={isProcessedAssignments}
              isProcessedNotes={isProcessedNotes}
              isTranscribed={isTranscribed}
              meetingStatus={meeting.status}
              onRedirect={onRedirect}
            />
          ) : (
            // Meeting is not processed yet
            <MeetingPlaceholderProcessing
              isProcessedAnalytics={isProcessedAnalytics}
              isProcessedAssignments={isProcessedAssignments}
              isProcessedNotes={isProcessedNotes}
              isPromoteUpgrade={isPromoteUpgrade}
              isTranscribed={isTranscribed}
              meetingDuration={meeting.duration}
              meetingEnd={meeting.finishedAt}
              isRestrictedKeyItems={isRestrictedKeyItemsPromotion}
              onChangeRoute={onRedirect}
              onClickOnUpgradePlan={onClickOnUpgradePlan}
            />
          )}
        </>
      )}

      {/* Begin: Menus */}
      {!!desktopMenuData && (
        <KeyItemMenuDesktop
          allowPinning={false}
          anchorEl={desktopMenuData.anchorEl}
          isActiveKeyItem={desktopMenuData.keyItem.isActive}
          hasTranscriptionItem={!!desktopMenuData.keyItem.diarizationItem?.id}
          isAuthorizedToEdit={meeting.permissions.canManage}
          onClickOnCopyContent={handleOnClickOnCopyKeyItemContent(desktopMenuData)}
          onClickOnCopyLink={handleOnClickOnCopyKeyItemLink(desktopMenuData)}
          onClickOnToggleActivity={handleToggleActivity(desktopMenuData)}
          onClickOnTranscriptLink={handleClickOnTranscriptLink(desktopMenuData)}
          onClose={() => setDesktopMenuData(null)}
          onMouseEnter={() => setDesktopMenuData(desktopMenuData)}
        />
      )}
      {!!mobileMenuData && (
        <KeyItemMenuMobile
          allowPinning={false}
          anchorEl={mobileMenuData.anchorEl}
          isActiveKeyItem={mobileMenuData.keyItem.isActive}
          hasTranscriptionItem={!!mobileMenuData.keyItem.diarizationItem?.id}
          isAuthorizedToEdit={meeting.permissions.canManage}
          onClose={handleCloseMobileMenu}
          onClickOnCopyContent={handleOnClickOnCopyKeyItemContent(mobileMenuData)}
          onClickOnCopyLink={handleOnClickOnCopyKeyItemLink(mobileMenuData)}
          onClickOnToggleActivity={handleToggleActivity(mobileMenuData)}
          onClickOnTranscriptLink={handleClickOnTranscriptLink(mobileMenuData)}
        />
      )}
      {!!integrationMenuData && (
        <KeyItemIntegrationsMenuContainer
          restrict3rdPartyIntegrations={restrict3rdPartyIntegrations}
          anchorEl={integrationMenuData.anchorEl}
          keyItemId={integrationMenuData.keyItemId}
          onClickOnDiscoverIntegrations={onClickOnDiscoverIntegrations}
          onClose={handleCloseIntegrationsMenu}
          onError={onResponseError}
          onSuccess={onPostingSuccess}
        />
      )}
      {!!changingDueDate && (
        <ActionDueDateMenu
          anchorEl={changingDueDate.anchorEl}
          actionId={changingDueDate.keyItemId}
          value={changingDueDate.dueDate}
          onClose={handleCloseDueDateChangeMenu}
          onChange={handleChangeDueDate(changingDueDate.keyItemType)}
        />
      )}
      {/* End: Menus */}
    </>
  );
};

function getActivityItemsCount(
  type: keyof Omit<NumKeyItems, '__typename'>,
  numKeyItems: NumKeyItems | undefined,
): {
  active: number;
  inactive: number;
  total: number;
} {
  const active = numKeyItems?.[type]?.active || 0;
  const inactive = numKeyItems?.[type]?.inactive || 0;
  const total = active + inactive;
  return { active, inactive, total };
}

function getNavItems(numKeyItems: NumKeyItems | undefined) {
  const activityTypes = Object.keys(ACTIVITY_TYPES) as Array<ActivityType>;
  return activityTypes.flatMap((id) => {
    const { title, countKey } = ACTIVITY_TYPES[id];
    const numberOfActivities = getActivityItemsCount(countKey, numKeyItems).total;
    return !!numberOfActivities ? { id, numberOfActivities, title } : [];
  });
}

export default KeyItemsContainer;
