import { useEffect, useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';
// Material Icons
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import MicIcon from '@material-ui/icons/Mic';
import PauseIcon from '@material-ui/icons/Pause';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import StopIcon from '@material-ui/icons/Stop';
// Lib Shared
import { RecordControlProps } from '../types';

export const FullRecordControl: React.FC<RecordControlProps> = ({
  children,
  disabled = false,
  displaySpeakingIndicator = false,
  hideDeleteButton = false,
  hidePauseButton = false,
  initialization = false,
  paused = false,
  speaking = false,
  title,
  onClickOnDelete = () => null,
  onClickOnPause = () => null,
  onClickOnResume = () => null,
  onClickOnStop = () => null,
}) => {
  const styles = useStyles();

  const [timer, setTimer] = useState(0);

  // Set timer that increments every second when recording is not paused
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (!paused) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [paused]);

  // Format the timer into HH:MM:SS
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      secs,
    ).padStart(2, '0')}`;
  };

  return (
    <div className={styles.root}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        p={2}
        mr={1}
        gridGap={4}
      >
        <MicIcon />
        <Typography variant="subtitle1">Record Audio</Typography>
      </Box>

      <div className={styles.body}>
        <div className={styles.timer}>{formatTime(timer)}</div>
        <div className={styles.title}>{title || 'Audio Recording'}</div>
      </div>

      {displaySpeakingIndicator && <>{children}</>}

      <div className={styles.foot}>
        {!hideDeleteButton && (
          <Tooltip arrow title="Delete Recording" placement="top">
            <Button
              variant="outlined"
              className={styles.outlineButton}
              disabled={disabled}
              startIcon={<DeleteIcon />}
              onClick={onClickOnDelete}
            >
              <Typography component="span" variant="body1">
                Delete
              </Typography>
            </Button>
          </Tooltip>
        )}
        {!hidePauseButton && (
          <>
            {paused ? (
              <Tooltip arrow open title="Resume Recording" placement="top">
                <IconButton
                  disabled={disabled}
                  className={styles.resumeButton}
                  onClick={onClickOnResume}
                >
                  <RecordVoiceOverIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip arrow title="Pause Recording" placement="top">
                <IconButton
                  disabled={disabled}
                  className={styles.contrastButton}
                  onClick={onClickOnPause}
                >
                  <PauseIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        <Tooltip arrow title="Complete Recording" placement="top">
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={styles.containedButton}
            disabled={disabled || initialization}
            onClick={onClickOnStop}
            startIcon={initialization ? <CircularProgress size={24} /> : <StopIcon />}
          >
            <Typography component="span" variant="body1">
              Done
            </Typography>
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.surface.main,
    color: theme.palette.surface.contrastText,
    minHeight: '100%',
    width: '100%',
    overflow: 'hidden',
    padding: theme.spacing(2, 0, 0),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  foot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(6),
    backgroundColor: theme.palette.surface.light,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(3),
    },
  },
  timer: {
    ...theme.typography.h1,
    fontSize: 64,
    marginBottom: theme.spacing(0.5),
  },
  title: {
    ...theme.typography.body1,
    opacity: 0.8,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  resumeButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  contrastButton: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      opacity: 0.95,
    },
  },
  outlineButton: {
    minWidth: 110,
    color: alpha(theme.palette.common.white, 0.7),
    borderColor: alpha(theme.palette.common.white, 0.25),
    '&:hover': {
      borderColor: alpha(theme.palette.common.white, 0.3),
      color: alpha(theme.palette.common.white, 0.8),
    },
  },
  containedButton: {
    minWidth: 110,
  },
}));

export default FullRecordControl;
