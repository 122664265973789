import { useEffect, useState, useCallback } from 'react';
// Sembly UI
import { useMediaDevices, getSelectedMicrophone } from '@sembly-ui';
// Material UI
import Box, { BoxProps } from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import MicIcon from '@material-ui/icons/MicSharp';

export interface RecorderSettingsBoxProps extends BoxProps {
  onChangeDevice: (deviceId: string, isVirtual: boolean) => void;
  onFailed: (message: string) => void;
}

export const RecorderSettingsBox: React.VFC<RecorderSettingsBoxProps> = ({
  onChangeDevice,
  onFailed,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [selectedDeviceId, setSelectedDeviceId] = useState('default');
  const [hasMediaStream, setHasMediaStream] = useState(false);

  const [deviceList] = useMediaDevices({ disabled: !hasMediaStream });
  /* #endregion */

  const startMediaStream = useCallback(
    async (deviceId: string): Promise<MediaStream | null> => {
      let stream: MediaStream | null = null;
      // check browser support for media devices and audio context
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        onFailed(`Sembly requires access to your microphone. Please allow microphone access in your
      browser's permission settings.`);
        return null;
      }

      // check microphone permission
      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } });
        stream.getTracks().forEach((track) => track.stop());
      } catch {
        onFailed(
          `Please allow microphone access in your browser's permission settings and reload the page.`,
        );
        return null;
      }

      return stream;
    },
    [onFailed],
  );

  const handleChangeDefaultDevice = async (e: React.ChangeEvent<{ value: unknown }>) => {
    const deviceId = e.target.value as string;
    const selectedDevice = deviceList?.find((item) => item.deviceId === deviceId);
    const selectedDeviceLabel = selectedDevice?.label.toLocaleLowerCase() ?? '';
    const isVirtual = selectedDeviceLabel.includes('virtual');

    if (selectedDevice) {
      const key = 'mics';
      const oldValue = localStorage.getItem(key);
      const newValue = JSON.stringify({ selectedDevice });

      localStorage.setItem(key, newValue);
      window.dispatchEvent(new StorageEvent('storage', { key, oldValue, newValue }));

      onChangeDevice(deviceId, isVirtual);
      const stream = await startMediaStream(deviceId);
      setHasMediaStream(!!stream);
      setSelectedDeviceId(selectedDevice.deviceId);
    }
  };

  /* #region  Effects */
  useEffect(() => {
    const storedDeviceId = getSelectedMicrophone()?.deviceId || 'default';
    setSelectedDeviceId(storedDeviceId);
    startMediaStream(storedDeviceId).then((stream) => {
      setHasMediaStream(!!stream);
    });
  }, [startMediaStream]);
  /* #endregion */

  return !!hasMediaStream ? (
    <Box mt={2}>
      <TextField
        fullWidth
        select
        label="Microphone"
        variant="outlined"
        value={selectedDeviceId}
        className={styles.select}
        SelectProps={{ native: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MicIcon color="action" className={styles.indicator} />
            </InputAdornment>
          ),
        }}
        onChange={handleChangeDefaultDevice}
      >
        {deviceList?.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </option>
        )) || <option disabled>There is no microphone available</option>}
      </TextField>
    </Box>
  ) : null;
};

const useStyles = makeStyles(() => {
  return {
    select: {
      '& select:focus': {
        background: 'transparent',
      },
    },
    indicator: {
      borderRadius: '50%',
      transition: 'all 0.35s ease-in-out',
      '&.animated': {
        animation: 'pulse-in 2s infinite',
      },
    },
  };
});

export default RecorderSettingsBox;
