import { createAuthProvider } from '@powowfe/common';
import { setCookie, removeCookie, getCookie } from 'typescript-cookie';
import { addBreadcrumb } from '@sentry/react';
// GraphQl Queries and Types
import revokeTokenMutation from '@shared/queries/RevokeToken.graphql';
import { RevokeToken } from '@gql-types';
// App Shared
import history from '@shared/clients/History';
import { AuthTokens } from '@shared/types';
import { CONFIG } from '@shared/configuration';
import { apolloClient } from '@shared/clients/ApolloClient';

const REVOKED_STORAGE_KEY = 'revoked' as const;
const LOGGEDIN_STORAGE_KEY = 'logged_in' as const;

const authCookieAttributes = {
  domain: window.location.hostname,
  path: '/',
  expires: new Date(2147483647 * 1000), // never expires
};

let pendingPromise: Promise<boolean> | null = null;

async function revokeToken(): Promise<boolean> {
  const result = await apolloClient.mutate<RevokeToken>({
    mutation: revokeTokenMutation,
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });

  return result.data?.revokeToken?.success ?? false;
}

async function requestRevokeToken() {
  if (!!localStorage.getItem(REVOKED_STORAGE_KEY)) {
    return (pendingPromise = null);
  }

  if (!pendingPromise) {
    pendingPromise = revokeToken().finally(() => {
      pendingPromise = null;
      localStorage.setItem(REVOKED_STORAGE_KEY, 'True');
    });
  }

  return pendingPromise;
}

window.addEventListener(
  'storage',
  (event) => {
    const isLoggedOut = event.key === LOGGEDIN_STORAGE_KEY && event.newValue !== 'True';
    if (isLoggedOut) logout();
  },
  false,
);

export const [useAuth, login, logout, getAuthToken] = createAuthProvider<AuthTokens>({
  localStorageKey: CONFIG.StorageAuthTokensKey,
  storage: {
    getItem: (key) => getCookie(key),
    removeItem: (key) => removeCookie(key, authCookieAttributes),
    setItem: (key, value) => setCookie(key, value, authCookieAttributes),
  },
  onLoggedOut: async () => {
    await requestRevokeToken();
    apolloClient.stop();
    await apolloClient.clearStore();
    await apolloClient.resetStore();
    await apolloClient.cache.reset();
    localStorage.setItem(LOGGEDIN_STORAGE_KEY, 'False');
    localStorage.setItem(REVOKED_STORAGE_KEY, 'True');
    localStorage.removeItem('remindExpiredSubscription');
    localStorage.removeItem(CONFIG.StorageSkipSemblianMemberOnboardingKey);

    // Clear the state from the location
    if (history.location.state) {
      const { state, ...location } = history.location;
      history.replace(location, null);
    }

    addBreadcrumb({
      category: 'auth',
      message: 'Logged out',
      level: 'info',
    });
  },
  onLogin: async () => {
    localStorage.setItem(LOGGEDIN_STORAGE_KEY, 'True');
    localStorage.removeItem(REVOKED_STORAGE_KEY);

    addBreadcrumb({
      category: 'auth',
      message: 'Logged in',
      level: 'info',
    });

    return true;
  },
});
