// Material UI
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';
// Material Icons
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
// Lib Shared
import { MeetingStatusChip, MeetingStatusInfo } from '../components';
import { AGENT_CALL_PLATFORMS } from '../constants';
import { GenericMeetingOverview } from '../types';
import { getNameInitials } from '../utils';

export interface SearchResultsProps {
  className?: string;
  data: GenericMeetingOverview[] | null | undefined;
  hasNextPage?: boolean;
  isFetchingMore?: boolean;
  itemClassName?: string;
  onClickOnItem: (id: string) => void;
  onClickOnNextPage: () => void;
}

export const SearchResults: React.VFC<SearchResultsProps> = ({
  className = '',
  data,
  hasNextPage = false,
  isFetchingMore = false,
  itemClassName = '',
  onClickOnItem,
  onClickOnNextPage,
}) => {
  const styles = useStyles();

  const handleClickOnListItem = (id: string) => (_: any) => {
    onClickOnItem(id);
  };

  const handleClickOnNextPage = (event: any) => {
    event.stopPropagation();
    onClickOnNextPage && onClickOnNextPage();
  };

  if (!data) return null;

  return (
    <List component="nav" className={className}>
      {data.map((entity) => {
        if (!entity) return null;
        const { id, title, agentCall, accessItems, searchCounter } = entity;
        const detectedCallPlatform = AGENT_CALL_PLATFORMS[agentCall?.platform || 'COMMON'];
        const isKnownPlatform = !!detectedCallPlatform;
        const platform = isKnownPlatform ? detectedCallPlatform : AGENT_CALL_PLATFORMS['COMMON'];

        return (
          <ListItem
            button
            key={id}
            className={`${styles.item} ${itemClassName}`}
            onClick={handleClickOnListItem(id)}
          >
            <Grid container className={styles.card} spacing={1} alignItems="center">
              <Grid item xs={12} sm>
                <div className={styles.title}>
                  <div className={styles.section}>
                    <ListItemAvatar>
                      <Avatar className={styles.icon} title={platform.title}>
                        {platform.icon}
                      </Avatar>
                    </ListItemAvatar>
                    <Typography component="span" variant="h6">
                      {title}
                    </Typography>
                  </div>
                  <MeetingStatusChip
                    ml={2}
                    meetingStatus={entity.status}
                    processingResult={entity.processingResults}
                  />
                </div>
                <Box ml={5}>
                  <MeetingStatusInfo
                    showStartDate
                    startDate={entity.startedAt}
                    finishDate={entity.finishedAt}
                    isRecurrentMeeting={entity.isRecurrent || false}
                    ownerFullName={entity.owner.fullName}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <div className={styles.action}>
                  <AvatarGroup
                    classes={{ root: styles.avatarGroup, avatar: styles.avatar }}
                    max={3}
                  >
                    {accessItems.map(({ id, email, user }) => {
                      const userName = user?.fullName || email || 'Guest';
                      return (
                        <Avatar
                          key={id}
                          title={userName}
                          alt={userName}
                          src={user?.avatar || undefined}
                        >
                          {user?.avatar ? null : getNameInitials(userName)}
                        </Avatar>
                      );
                    })}
                  </AvatarGroup>
                  {!!searchCounter && (
                    <div className={styles.chip}>
                      <SearchOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        className={styles.highlight}
                      />
                      <Typography variant="body2" component="span" className={styles.label}>
                        {`Instances: ${searchCounter}`}
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
      {hasNextPage && (
        <Box width="100%" display="flex" justifyContent="center">
          <Box my={2}>
            <Button variant="outlined" onClick={handleClickOnNextPage} endIcon={isFetchingMore && <CircularProgress size={18} />}>
              <Typography component="span" variant="body1">
                More Results
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  action: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      marginLeft: theme.spacing(5),
    },
  },
  avatarGroup: {
    justifyContent: 'flex-end',
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '0.875rem',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  item: {
    cursor: 'pointer',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
  },
  card: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2rem',
    backgroundColor: alpha(theme.palette.highlight.main, 0.25),
    padding: theme.spacing(1 / 4, 1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1 / 2, 1),
    },
  },
  icon: {
    width: 32,
    height: 32,
    border: '1px solid transparent',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.highlight.dark,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default SearchResults;
