import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
// Sembly UI
import {
  formatInTimeZone,
  getMicrophonesList,
  getSelectedMicrophone,
  useConfirmationDialog,
  VoiceRecorder,
  VoiceRecorderError,
  VoiceRecorderResultMeta,
  VoiceRecordingChunk,
} from '@sembly-ui';
// App Shared
import { Languages, RecordDevices, StartLiveMeetingVariables } from '@gql-types';
import { SMARTMEETING_DEVICE_ID, SMARTMIKE_DEVICE_ID } from '@shared/configuration';
// Module Shared
import AudioRecordingIsTooQuiet from './dialogs/AudioRecordingIsTooQuiet';
import AudioRecordingIsTooShort from './dialogs/AudioRecordingIsTooShort';
import ConfirmDeleteRecording from './dialogs/ConfirmDeleteRecording';
import DisconnectedMicrophone from './dialogs/DisconnectedMicrophone';
import MicIsBlocked from './dialogs/MicIsBlocked';
import StartConfirmation from './dialogs/StartConfirmation';
import UnsupportedBrowser from './dialogs/UnsupportedBrowser';

/* #region  Types */
enum GenericRecordingDevice {
  generic = 'generic',
}

type ErrorCode = VoiceRecorderError | 'TOO_SHORT' | 'TOO_QUIET';
type LocalMeetingRecordingDevice = RecordDevices | GenericRecordingDevice;
type StartRecordingConfirmationResult = {
  title: string;
  primaryLang: Languages;
  additionalLang: Languages | null;
} | null;

export interface LocalMeetingRecorderChunk extends VoiceRecordingChunk {
  title: string;
  finishedAt: number;
  startedAt: number;
}

export interface LocalMeetingRecorderProps {
  completingMeeting?: boolean;
  maxDurationSeconds?: number;
  initializingMeeting?: boolean;
  chunkMaxDurationSeconds?: number;
  forceRecordingStart?: boolean; // starts recording immediately after the recorder is initialized
  restartRecordingAtMaxDuration?: boolean; // automatic restart of the recording when the maximum duration is reached
  recordingDeviceType?: LocalMeetingRecordingDevice;
  skipStartConfirmation?: boolean;
  skipDeleteConfirmation?: boolean;
  ui?: 'compact' | 'full';
  onCancelRecording?: () => void;
  onDataAvailable?: (data: LocalMeetingRecorderChunk) => void;
  onDeleteRecord?: () => void;
  onError?: () => void;
  onStartRecording?: (data: StartLiveMeetingVariables) => Promise<boolean>;
}
/* #endregion */

export const LocalMeetingRecorder: React.FC<LocalMeetingRecorderProps> = ({
  children,
  completingMeeting = false,
  chunkMaxDurationSeconds = 2 * 60, // 2 minutes
  maxDurationSeconds = 2 * 60 * 60, // 2 hours
  forceRecordingStart = false,
  initializingMeeting = false,
  recordingDeviceType = GenericRecordingDevice.generic,
  restartRecordingAtMaxDuration = false,
  skipStartConfirmation = false,
  skipDeleteConfirmation = false,
  ui = 'full',
  onCancelRecording = () => null,
  onDataAvailable = () => null,
  onDeleteRecord = () => null,
  onError = () => null,
  onStartRecording = () => true,
}) => {
  /* #region  Hooks */
  const toastRef = useRef<React.ReactText | null>(null);
  const meetingTitleRef = useRef(`Audio Recording ${formatInTimeZone(new Date(), 'PP p')}`);

  const [idealDeviceId, setIdealDeviceId] = useState<string | null | undefined>(null);
  const [error, setError] = useState<ErrorCode | null>(null);

  const [confirmStartRecording, startRecordingConfirmationDialog] =
    useConfirmationDialog<StartRecordingConfirmationResult>((resolve) => (
      <StartConfirmation
        open
        processing={completingMeeting}
        onSubmit={(data) => resolve(data)}
        onCancel={() => resolve(null)}
      />
    ));

  const [confirmDeleteRecording, deleteRecordingConfirmationDialog] = useConfirmationDialog(
    (resolve) => (
      <ConfirmDeleteRecording
        open
        onConfirm={() => resolve(true)}
        onCancel={() => resolve(false)}
      />
    ),
  );
  /* #endregion */

  /* #region  Handlers */
  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleRequestStartRecording = async (partNumber: number) => {
    if (skipStartConfirmation || partNumber > 1) {
      return await handleStartRecording(partNumber);
    }

    const result = await confirmStartRecording();

    if (!result) {
      onCancelRecording();
      return false;
    }

    meetingTitleRef.current = result.title;
    return await handleStartRecording(partNumber, result.primaryLang, result.additionalLang);
  };

  const handleStartRecording = async (
    partNumber: number = 0,
    primaryLanguage?: Languages,
    additionalLanguage?: Languages | null,
  ) => {
    const recordDevice = isSmartDeviceUser ? recordingDeviceType : null;

    let title = meetingTitleRef.current;
    if (partNumber > 1) title = `${title} (Part ${partNumber})`;

    const isStarted = await onStartRecording(
      !!primaryLanguage
        ? {
            title,
            recordDevice,
            startedAt: getTimestamp(),
            languages: {
              primaryLanguage,
              additionalLanguage,
            },
          }
        : {
            title,
            recordDevice,
            startedAt: getTimestamp(),
          },
    );

    if (!isStarted) {
      onCancelRecording();
    }

    return isStarted;
  };

  const handleSubmitRecordingChunk = async (
    chunk: VoiceRecordingChunk,
    meta: VoiceRecorderResultMeta,
  ) => {
    const date = formatInTimeZone(new Date(), 'PP p');
    const title = `${meetingTitleRef.current} (chunk ${date})`;

    if (chunk.isRecordingCompleted) {
      const hasRequiredDuration = chunk.recordingDurationSeconds > 2;

      if (!hasRequiredDuration) {
        setError('TOO_SHORT');
        onDeleteRecord();
        return;
      }
    }

    onDataAvailable({
      ...chunk,
      title,
      finishedAt: getTimestamp(chunk.endTime),
      startedAt: getTimestamp(chunk.startTime),
    });
  };

  const handleConfirmRecordDeletion = async () => {
    if (skipDeleteConfirmation) {
      return true;
    } else {
      return await confirmDeleteRecording();
    }
  };

  const handleRecordingError = (error: VoiceRecorderError) => {
    setError(error);
    onError();
    onDeleteRecord();
  };

  const handleShowSilenceNotification = () => {
    const isAlreadyNotified = toastRef.current && toast.isActive(toastRef.current);
    const message = `We can't hear you. Is your microphone muted? Please make sure your microphone is working.`;
    if (!isAlreadyNotified) toastRef.current = toast.dark(message);
  };
  /* #endregion */

  const isSmartMikeDeviceUser = recordingDeviceType === RecordDevices.smartmike;
  const isSmartMeetingDeviceUser = recordingDeviceType === RecordDevices.smartmeeting;
  const isSmartDeviceUser = isSmartMeetingDeviceUser || isSmartMikeDeviceUser;
  const isDisconnectedMicrophone = error === 'NO_DEVICE_AVAILABLE';

  useEffect(() => {
    async function id(deviceName: string) {
      const devices = await getMicrophonesList({ deviceName });
      return devices.length ? devices[0].deviceId : undefined;
    }

    async function init(ev?: StorageEvent) {
      if (ev && ev.key !== 'mics') return; // avoid unnecessary events

      const recommendedDeviceId = getSelectedMicrophone()?.deviceId;
      const smartMikeDeviceId = isSmartMikeDeviceUser ? await id(SMARTMIKE_DEVICE_ID) : undefined;
      const smartMeetingDeviceId = isSmartMeetingDeviceUser
        ? await id(SMARTMEETING_DEVICE_ID)
        : undefined;

      setIdealDeviceId(recommendedDeviceId || smartMikeDeviceId || smartMeetingDeviceId);
    }

    init();
    window.addEventListener('storage', (e) => init(e));

    return () => {
      window.removeEventListener('storage', (e) => init(e));
    };
  }, [isSmartMikeDeviceUser, isSmartMeetingDeviceUser]);

  // wait for device detection result
  if (idealDeviceId === null) return null;

  return (
    <>
      <VoiceRecorder
        ui={ui}
        chunkMaxDurationSeconds={chunkMaxDurationSeconds}
        disabled={initializingMeeting}
        forceRecordingStart={forceRecordingStart}
        idealDeviceId={idealDeviceId}
        key={idealDeviceId}
        maxDurationSeconds={maxDurationSeconds}
        restartRecordingAtMaxDuration={restartRecordingAtMaxDuration}
        title={meetingTitleRef.current}
        wrapperElementProps={{ display: 'flex', alignItems: 'center' }}
        onDataAvailable={handleSubmitRecordingChunk}
        onDelete={onDeleteRecord}
        onDeleteConfirmation={handleConfirmRecordDeletion}
        onError={handleRecordingError}
        onSilenceDetected={handleShowSilenceNotification}
        onStart={handleRequestStartRecording}
      >
        {children}
      </VoiceRecorder>

      {/* Begin: Modal Dialogs */}
      <AudioRecordingIsTooQuiet open={error === 'TOO_QUIET'} onClose={handleCloseErrorDialog} />
      <AudioRecordingIsTooShort open={error === 'TOO_SHORT'} onClose={handleCloseErrorDialog} />
      <DisconnectedMicrophone open={isDisconnectedMicrophone} onClose={handleCloseErrorDialog} />
      <MicIsBlocked open={error === 'PERMISSION_DENIED'} onClose={handleCloseErrorDialog} />
      <UnsupportedBrowser open={error === 'UNSUPPORTED_BROWSER'} onClose={handleCloseErrorDialog} />

      {deleteRecordingConfirmationDialog}
      {startRecordingConfirmationDialog}
      {/* End: Modal Dialogs */}
    </>
  );
};

const getTimestamp = (date: number = Date.now()) => Math.floor(date / 1000);

export default LocalMeetingRecorder;
