/**
 * Allows to set values that persist across all the subsequent gtag() calls on the app.
 */
export const set = (params: Gtag.CustomParams, userData?: Gtag.CustomParams ) => {
  if (!window.gtag) return;
  if (!process.env.REACT_APP_GTM_ID) {
    console.warn('Tracking not enabled, as `trackingId` was not given and there is no `GA_ID`.');
    return;
  }

  window.gtag('set', params);

  if (userData) {
    window.gtag('set', { userData });
  }
};
