import throttle from 'lodash/throttle';
import { useEffect, useState, useRef } from 'react';

/* #region  Types */
export type UseVoiceDetectionResult = Readonly<[
  boolean,
  { isUnsupported: boolean; frequencyData: Uint8Array; getFrequencyData: () => Uint8Array }]
>;

export type UseVoiceDetectionOptions = Readonly<{
  trottle?: number;
  minDecibels?: number;
}>;
/* #endregion */

export function useVoiceDetection(
  mediaStream: MediaStream | null | undefined,
  options?: UseVoiceDetectionOptions,
): UseVoiceDetectionResult {
  const MIN_DECEBELS = options?.minDecibels ?? (-40 as const);
  const THROTTLE = options?.trottle ?? (500 as const);

  /* #region  Hooks */
  const [isVoiceDetecting, setIsVoiceDetecting] = useState(false);
  const [isUnsupported, setIsUnsupported] = useState(false);
  const [frequencyData, setFrequencyData] = useState(new Uint8Array());

  const isMountedRef = useRef(false);
  const intervalRef = useRef<number>();
  const analyserRef = useRef<AnalyserNode | null>(null);
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!mediaStream) return;

    let analyser: AnalyserNode;
    let streamNode: MediaStreamAudioSourceNode | undefined;
    let dataArray: Uint8Array;

    try {
      const audioCtx = new AudioContext();

      analyser = audioCtx.createAnalyser();
      analyser.minDecibels = MIN_DECEBELS;

      streamNode = audioCtx.createMediaStreamSource(mediaStream);

      dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyserRef.current = analyser;
      streamNode.connect(analyser);
      setFrequencyData(new Uint8Array(analyser.frequencyBinCount));
    } catch {
      setIsUnsupported(true);
      return;
    }

    const setResult = throttle((hasSignal: boolean) => {
      if (!isMountedRef.current) return;
      setIsVoiceDetecting(hasSignal);
    }, THROTTLE);

    const analyze = () => {
      analyser.getByteFrequencyData(dataArray);
      setFrequencyData(new Uint8Array(dataArray));
      const hasSignal = dataArray.some((frequency) => frequency);
      setResult(hasSignal);
      intervalRef.current = requestAnimationFrame(analyze);
    };

    intervalRef.current = requestAnimationFrame(analyze);

    return () => {
      if (intervalRef.current) cancelAnimationFrame(intervalRef.current);
      if (analyser) analyser.disconnect();
      if (streamNode) streamNode.disconnect();
      analyserRef.current = null;
    };
  }, [mediaStream, THROTTLE, MIN_DECEBELS]);

  const getFrequencyData = () => {
    if (analyserRef.current) {
      const data = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(data);
      return data;
    }
    return new Uint8Array();
  };
  /* #endregion */

  return [
    isVoiceDetecting,
    { isUnsupported, frequencyData, getFrequencyData },
  ];
}

export default useVoiceDetection;
