import capitalize from 'lodash/capitalize';
import pluralize from 'pluralize';
import { formatISO, subDays, subMonths, subYears } from 'date-fns';
import { NetworkStatus, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { useState, useEffect } from 'react';
// Material UI
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/CloseSharp';
import CloseIcon from '@material-ui/icons/Close';
import FilterIcon from '@material-ui/icons/FilterListSharp';
import HistoryIcon from '@material-ui/icons/History';
import SearchIcon from '@material-ui/icons/Search';
// Lib Shared
import { MEETING_TYPES } from '../constants';
import FiltersOffIcon from '../icons/FiltersOff';
import GenericDialog from '../components/GenericDialog';
import searchImage from '../assets/icon-search-36.svg';
import SearchResults from '../components/SearchResults';
// Lib GraphQL Queries and Types
import clearAllMutation from '../graphql/mutations/ClearAllRecentSearches.graphql';
import clearItemMutation from '../graphql/mutations/ClearRecentSearchItem.graphql';
import searchHistoryQuery from '../graphql/queries/AdvancedSearchHistoryItems.graphql';
import searchQuery from '../graphql/queries/MeetingsSearch.graphql';
import workspaceParticipantsQuery from '../graphql/queries/WorkspaceParticipants.graphql';
import {
  AdvancedSearchHistoryItems,
  AdvancedSearchHistoryItemsVariables,
  AgentCallPlatforms,
  ClearAllRecentSearches,
  ClearRecentSearchItem,
  ClearRecentSearchItemVariables,
  MeetingsSearch,
  MeetingsSearchVariables,
  MeetingStatuses,
  MeetingTypes,
  WorkspaceParticipants,
  WorkspaceParticipantsVariables,
} from '../types';

enum DateRangeType {
  LAST_SEVEN_DAYS = 'LAST_SEVEN_DAYS',
  LAST_THIRTY_DAYS = 'LAST_THIRTY_DAYS',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
  LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  ALL = 'ALL',
}

enum SortByType {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  RELEVANCE = 'RELEVANCE',
}

type Participants = { name: string; email: string | null }[];

export interface AdvancedSearchDialogProps {
  open: boolean;
  isSmartMeetingUser: boolean;
  onClose: () => void;
  onClickOnMeetingCard: (id: string) => void;
}

export const AdvancedSearchDialog: React.VFC<AdvancedSearchDialogProps> = ({
  open,
  isSmartMeetingUser,
  onClose,
  onClickOnMeetingCard,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentValue, setCurrentValue] = useState('');
  const [meetingSourceValue, setMeetingSourceValue] = useState<AgentCallPlatforms | 'ALL'>('ALL');
  const [meetingTypeValue, setMeetingTypeValue] = useState<MeetingTypes | 'ALL'>('ALL');
  const [sortByValue, setSortByValue] = useState<SortByType>(SortByType.NEWEST);
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeType>(DateRangeType.ALL);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [participantsMenuEl, setParticipantsMenuEl] = useState<null | HTMLElement>(null);
  const [participantsFilterValue, setParticipantsFilterValue] = useState('');
  const [tempParticipantsFilterValue, setTempParticipantsFilterValue] = useState<Participants>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<Participants>([]);
  const [deletedHistoryItems, setDeletedHistoryItems] = useState<string[]>([]);

  const {
    data: historyData,
    refetch: refetchSearchHistory,
    networkStatus: searchHistoryNetworkStatus,
  } = useQuery<AdvancedSearchHistoryItems, AdvancedSearchHistoryItemsVariables>(
    searchHistoryQuery,
    {
      variables: { limit: 6 },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: !open,
    },
  );

  const {
    data: participantsData,
    loading: isLoadingParticipants,
    refetch: refetchParticipants,
    fetchMore: fetchMoreParticipants,
  } = useQuery<WorkspaceParticipants, WorkspaceParticipantsVariables>(workspaceParticipantsQuery, {
    variables: { search: participantsFilterValue },
    skip: !open,
  });

  const [find, { fetchMore, refetch, data, called, networkStatus }] = useLazyQuery<
    MeetingsSearch,
    MeetingsSearchVariables
  >(searchQuery, { notifyOnNetworkStatusChange: true });

  const [clearAllRecentSearches, { loading: isClearingAll }] =
    useMutation<ClearAllRecentSearches>(clearAllMutation);

  const [clearRecentSearchItem] = useMutation<
    ClearRecentSearchItem,
    ClearRecentSearchItemVariables
  >(clearItemMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleClickClearAllRecentSearches = async () => {
    await clearAllRecentSearches();
    refetchSearchHistory();
  };

  const handleClickClearRecentSearchItem = (id: string) => () => {
    deletedHistoryItems.push(id);
    clearRecentSearchItem({
      variables: { id: +id },
      optimisticResponse: {
        clearRecentSearchItem: {
          __typename: 'ClearRecentSearchItemMutationPayload',
          success: true,
          errors: null,
        },
      },
      onCompleted: (result) => {
        if (result.clearRecentSearchItem?.success) {
          refetchSearchHistory();
        } else {
          setDeletedHistoryItems(deletedHistoryItems.filter((item) => item !== id));
        }
      },
    });
  };

  const handleChangeSearchTerm = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let { value } = event.target;
    if (!value.trim().length) value = '';
    setCurrentValue(value);
    handleSearchDebounced(value);
  };

  const handleSearch = async (searchValue: string, page = 1, perPage = 6) => {
    const search = searchValue ? searchValue.toLowerCase() : '';
    const participants = selectedParticipants.length ? selectedParticipants : undefined;
    const meetingType = meetingTypeValue === 'ALL' ? undefined : meetingTypeValue;
    const source = meetingSourceValue === 'ALL' ? undefined : meetingSourceValue;

    let meetingTeam: string | null | undefined = undefined;
    let startDate = undefined;
    let endDate = undefined;
    let orderBy = 'started_at';

    switch (dateRangeValue) {
      case DateRangeType.LAST_SEVEN_DAYS:
        startDate = formatISO(subDays(new Date(), 7), { representation: 'date' });
        break;
      case DateRangeType.LAST_THIRTY_DAYS:
        startDate = formatISO(subDays(new Date(), 30), { representation: 'date' });
        break;
      case DateRangeType.LAST_THREE_MONTHS:
        startDate = formatISO(subMonths(new Date(), 3), { representation: 'date' });
        break;
      case DateRangeType.LAST_SIX_MONTHS:
        startDate = formatISO(subMonths(new Date(), 6), { representation: 'date' });
        break;
      case DateRangeType.LAST_YEAR:
        startDate = formatISO(subYears(new Date(), 1), { representation: 'date' });
        break;
      case DateRangeType.ALL:
        startDate = undefined;
        endDate = undefined;
        break;
    }

    switch (sortByValue) {
      case SortByType.NEWEST:
        orderBy = '-started_at';
        break;
      case SortByType.OLDEST:
        orderBy = 'started_at';
        break;
      case SortByType.RELEVANCE:
        orderBy = '-search_counter';
        break;
    }

    if (called && refetch) {
      await refetch({
        endDate,
        meetingTeam,
        meetingType,
        orderBy,
        page,
        participants,
        perPage,
        search,
        source,
        startDate,
        statuses: [MeetingStatuses.submitted],
      });
    } else {
      await find({
        variables: {
          endDate,
          meetingTeam,
          meetingType,
          orderBy,
          page,
          participants,
          perPage,
          search,
          source,
          startDate,
          statuses: [MeetingStatuses.submitted],
        },
      });
    }

    refetchSearchHistory();
  };

  const handleSearchDebounced = useDebouncedCallback((value?: string) => {
    handleSearch(value || currentValue);
  }, 1000);

  const handleClearSearchTerm = () => {
    setCurrentValue('');
    handleSearchDebounced('');
  };

  const handleChangeDateRangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDateRangeValue(event.target.value as DateRangeType);
    handleSearchDebounced();
  };

  const handleChangeSortType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortByValue(event.target.value as SortByType);
    handleSearchDebounced();
  };

  const handleChangeMeetingType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMeetingTypeValue((event.target.value as MeetingTypes | 'ALL' | undefined) || 'ALL');
    handleSearchDebounced();
  };

  const handleChangeMeetingSource = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMeetingSourceValue(event.target.value as AgentCallPlatforms | 'ALL');
    handleSearchDebounced();
  };

  const handleSearchHistoryClick = (search: string) => () => {
    setCurrentValue(search);
    handleSearch(search);
  };

  const handleRequestNextPage = () => {
    const currentPage = data?.meetingsPaginated?.page ?? 0;
    const search = currentValue ? currentValue.toLowerCase() : '';
    const participants = selectedParticipants.length ? selectedParticipants : undefined;
    const meetingType = meetingTypeValue === 'ALL' ? undefined : meetingTypeValue;
    const source = meetingSourceValue === 'ALL' ? undefined : meetingSourceValue;

    let meetingTeam: string | null | undefined = undefined;
    let startDate = undefined;
    let endDate = undefined;
    let orderBy = 'started_at';

    switch (dateRangeValue) {
      case DateRangeType.LAST_SEVEN_DAYS:
        startDate = formatISO(subDays(new Date(), 7), { representation: 'date' });
        break;
      case DateRangeType.LAST_THIRTY_DAYS:
        startDate = formatISO(subDays(new Date(), 30), { representation: 'date' });
        break;
      case DateRangeType.LAST_THREE_MONTHS:
        startDate = formatISO(subMonths(new Date(), 3), { representation: 'date' });
        break;
      case DateRangeType.LAST_SIX_MONTHS:
        startDate = formatISO(subMonths(new Date(), 6), { representation: 'date' });
        break;
      case DateRangeType.LAST_YEAR:
        startDate = formatISO(subYears(new Date(), 1), { representation: 'date' });
        break;
      case DateRangeType.ALL:
        startDate = undefined;
        endDate = undefined;
        break;
    }

    switch (sortByValue) {
      case SortByType.NEWEST:
        orderBy = '-started_at';
        break;
      case SortByType.OLDEST:
        orderBy = 'started_at';
        break;
      case SortByType.RELEVANCE:
        orderBy = '-search_counter';
        break;
    }

    fetchMore({
      variables: {
        search,
        page: currentPage + 1,
        endDate,
        meetingTeam,
        meetingType,
        orderBy,
        participants,
        perPage: 6,
        source,
        startDate,
        statuses: [MeetingStatuses.submitted],
      },
    });
  };

  const handleRefetchParticipants = useDebouncedCallback((value) => {
    refetchParticipants({ search: value });
  }, 1000);

  const handleLoadMoreParticipants = () => {
    const currentPage = participantsData?.workspaceParticipantsPaginated?.page ?? 0;

    fetchMoreParticipants({
      variables: {
        page: currentPage + 1,
        search: participantsFilterValue,
      },
    });
  };

  const handleClearParticipantFilterValue = () => {
    setParticipantsFilterValue('');
    refetchParticipants({ search: participantsFilterValue });
  };

  const handleChangeParticipantFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParticipantsFilterValue(event.target.value);
    handleRefetchParticipants(event.target.value);
  };

  const handleClickOnParticipantItem =
    ({ name, email }: { name: string; email: string | null }) =>
    () => {
      const indexOfSelectedParticipant = tempParticipantsFilterValue.findIndex(
        (item) => item.name === name && item.email === email,
      );

      setTempParticipantsFilterValue((prev) =>
        indexOfSelectedParticipant === -1
          ? [...prev, { name, email }]
          : prev.filter((item, index) => index !== indexOfSelectedParticipant),
      );
    };

  const handleApplyParticipantFilter = () => {
    setParticipantsMenuEl(null);
    setSelectedParticipants(tempParticipantsFilterValue);
    handleSearchDebounced();
  };

  const handleResetParticipantFilter = () => {
    setTempParticipantsFilterValue([]);
  };

  const handleClearAllFilters = () => {
    setMeetingSourceValue('ALL');
    setMeetingTypeValue('ALL');
    setDateRangeValue(DateRangeType.ALL);
    setSortByValue(SortByType.NEWEST);
    setSelectedParticipants([]);
    handleSearchDebounced();
  };

  const handleOpenParticipantsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setParticipantsMenuEl(event.currentTarget);
    setTempParticipantsFilterValue(selectedParticipants);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const fetchingStatuses = [
    NetworkStatus.loading,
    NetworkStatus.setVariables,
    NetworkStatus.refetch,
  ];

  const isFetching = fetchingStatuses.includes(networkStatus);
  const hasMoreSearchResults = data?.meetingsPaginated?.hasNext ?? false;
  const hasMoreParticipants = participantsData?.workspaceParticipantsPaginated?.hasNext ?? false;
  const participantItems = participantsData?.workspaceParticipantsPaginated?.objects ?? [];

  const meetingTypesList = MEETING_TYPES.flatMap((item) => [
    item.category,
    ...item.elements.flatMap((element) => element),
  ]);

  const isFiltered =
    !!selectedParticipants.length ||
    dateRangeValue !== DateRangeType.ALL ||
    meetingSourceValue !== 'ALL' ||
    meetingTypeValue !== 'ALL';

  const filtersCount = [
    !!selectedParticipants.length,
    !!dateRangeValue && dateRangeValue !== DateRangeType.ALL,
    !!meetingSourceValue && meetingSourceValue !== 'ALL',
    !!meetingTypeValue && meetingTypeValue !== 'ALL',
  ].filter((item) => !!item).length;

  const hasSearchRequest = !!currentValue || isFiltered;

  const dateRangeTypes = [
    { value: DateRangeType.LAST_SEVEN_DAYS, label: 'Last 7 days' },
    { value: DateRangeType.LAST_THIRTY_DAYS, label: 'Last 30 days' },
    { value: DateRangeType.LAST_THREE_MONTHS, label: 'Last 3 months' },
    { value: DateRangeType.LAST_SIX_MONTHS, label: 'Last 6 months' },
    { value: DateRangeType.LAST_YEAR, label: 'Last year' },
    { value: DateRangeType.ALL, label: 'All time' },
  ];

  let meetingPlatforms = [
    { value: 'ALL', label: 'All meeting sources' },
    { value: AgentCallPlatforms.google_meet, label: 'Google Meet' },
    { value: AgentCallPlatforms.ms_teams, label: 'Microsoft Teams' },
    { value: AgentCallPlatforms.webex, label: 'Webex' },
    { value: AgentCallPlatforms.zoom, label: 'Zoom' },
    { value: AgentCallPlatforms.manual_record, label: 'Recorded audio' },
    { value: AgentCallPlatforms.manual_upload, label: 'Uploaded media' },
    { value: AgentCallPlatforms.common, label: 'Other' },
  ];

  if (isSmartMeetingUser) {
    meetingPlatforms = [
      ...meetingPlatforms,
      { value: AgentCallPlatforms.smartmeeting_record, label: 'SmartMeeting Record' },
    ];
  }
  /* #endregion */

  useEffect(() => {
    if (open) {
      refetchParticipants();
    }
  }, [open, refetchParticipants]);

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth="lg"
        fullScreen={isSmallScreen}
        className={styles.dialog}
        onClose={onClose}
      >
        <DialogTitle disableTypography className={styles.dialogTitle}>
          <Box display="flex" alignItems="center" width="100%" mb={3}>
            <Box flex={1} display="flex" alignItems="center" gridGap={16}>
              <Typography component="div" variant="h5">
                {isSmallScreen ? 'Search' : 'Advanced Search'}
              </Typography>
              {isFiltered && !isSmallScreen && (
                <Button
                  disableFocusRipple
                  variant="outlined"
                  title="Reset all filters"
                  fullWidth={isSmallScreen}
                  startIcon={<FiltersOffIcon fontSize="small" />}
                  onClick={handleClearAllFilters}
                >
                  <Typography component="span" variant="body1">
                    Reset All
                  </Typography>
                </Button>
              )}
            </Box>
            <Button
              variant="outlined"
              title="Dismiss search"
              startIcon={<CloseIcon />}
              onClick={onClose}
            >
              <Typography component="span" variant="body1" color="inherit">
                Close
              </Typography>
            </Button>
          </Box>
          <Box display="flex" alignItems="center" gridGap={8}>
            <TextField
              fullWidth
              variant="filled"
              placeholder="Search meetings"
              value={currentValue}
              inputProps={{ maxLength: 255 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {isFetching ? <CircularProgress size={24} /> : <SearchIcon color="action" />}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!!currentValue && (
                      <Tooltip arrow placement="top" title="Clear search">
                        <IconButton className={styles.iconButton} onClick={handleClearSearchTerm}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
                classes: { input: styles.searchFieldInput },
              }}
              onChange={handleChangeSearchTerm}
            />
            {isSmallScreen && (
              <Badge overlap="circular" color="primary" badgeContent={filtersCount}>
                <IconButton
                  className={styles.iconButtonOutlined}
                  onClick={() => setShowAllFilters(true)}
                  title="Show all filters"
                >
                  <FilterIcon color="action" />
                </IconButton>
              </Badge>
            )}
          </Box>

          {!isSmallScreen && (
            <div className={styles.toolbar}>
              <div className={styles.toolbarSection}>
                <FormControl
                  size="small"
                  variant="filled"
                  title="Date range"
                  fullWidth={isSmallScreen}
                >
                  <Select value={dateRangeValue} onChange={handleChangeDateRangeType}>
                    {dateRangeTypes.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  size="small"
                  variant="filled"
                  title="Meeting participants"
                  fullWidth={isSmallScreen}
                >
                  <TextField
                    size="small"
                    variant="filled"
                    placeholder="Select participants"
                    value={
                      selectedParticipants.length
                        ? `${selectedParticipants.length} ${pluralize(
                            'participant',
                            selectedParticipants.length,
                          )}`
                        : 'All participants'
                    }
                    InputProps={{
                      readOnly: true,
                      style: { cursor: 'pointer', paddingRight: 0, userSelect: 'none' },
                      classes: { input: styles.textFieldInput },
                      endAdornment: (
                        <InputAdornment position="start">
                          {isLoadingParticipants ? (
                            <CircularProgress size={20} />
                          ) : (
                            <>
                              {!!participantsMenuEl ? (
                                <ArrowDropUpIcon color="action" />
                              ) : (
                                <ArrowDropDownIcon color="action" />
                              )}
                            </>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ style: { cursor: 'pointer' } }}
                    onClick={handleOpenParticipantsMenu}
                  />
                </FormControl>

                <FormControl
                  size="small"
                  variant="filled"
                  title="Meeting type"
                  fullWidth={isSmallScreen}
                >
                  <Select value={meetingTypeValue} onChange={handleChangeMeetingType}>
                    <MenuItem value="ALL">All meeting types</MenuItem>
                    {meetingTypesList.map((item) =>
                      typeof item === 'string' ? (
                        <ListSubheader key={item} classes={{ root: styles.listSubHeaderRoot }}>
                          {item}
                        </ListSubheader>
                      ) : (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          classes={{ root: styles.menuItemRoot }}
                          selected={meetingTypeValue === item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  size="small"
                  variant="filled"
                  title="Meeting source"
                  fullWidth={isSmallScreen}
                >
                  <Select fullWidth value={meetingSourceValue} onChange={handleChangeMeetingSource}>
                    {meetingPlatforms.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className={styles.toolbarSection}>
                <FormControl
                  size="small"
                  variant="filled"
                  title="Sort order"
                  fullWidth={isSmallScreen}
                >
                  <Select
                    value={sortByValue}
                    renderValue={(val) => `Sort by: ${capitalize(val as string)}`}
                    onChange={handleChangeSortType}
                  >
                    <MenuItem value={SortByType.NEWEST}>Newest</MenuItem>
                    <MenuItem value={SortByType.OLDEST}>Oldest</MenuItem>
                    <MenuItem value={SortByType.RELEVANCE}>Relevance</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          {hasSearchRequest ? (
            <>
              {!data?.meetingsPaginated?.objects?.length ? (
                <div className={styles.container}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    gridGap={18}
                    m={2}
                    py={8}
                    visibility={isFetching || !called ? 'hidden' : 'visible'}
                  >
                    <img width={36} alt="Search" src={searchImage} />
                    <div>
                      <Typography gutterBottom variant="h6" align="center">
                        Nothing found
                      </Typography>
                      <Typography variant="body1" align="center">
                        You may want to try using different keywords, checking for typos, or
                        adjusting your filters.
                      </Typography>
                    </div>
                  </Box>
                </div>
              ) : (
                <>
                  {!isFetching && (
                    <SearchResults
                      className={styles.container}
                      data={data.meetingsPaginated.objects}
                      hasNextPage={hasMoreSearchResults}
                      isFetchingMore={networkStatus === NetworkStatus.fetchMore}
                      itemClassName={styles.searchItem}
                      onClickOnItem={onClickOnMeetingCard}
                      onClickOnNextPage={handleRequestNextPage}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <div className={styles.container}>
              {searchHistoryNetworkStatus === NetworkStatus.loading ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  m={2}
                  py={8}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {historyData?.meetingsSearchHistory?.length ? (
                    <Box px={2}>
                      <List
                        subheader={
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gridGap={16}
                          >
                            <ListSubheader>
                              <Box display="flex" alignItems="center" gridGap={16}>
                                <span>Recent searches</span>
                                {searchHistoryNetworkStatus === NetworkStatus.refetch && (
                                  <CircularProgress size={16} />
                                )}
                              </Box>
                            </ListSubheader>
                            <Button
                              size="small"
                              color="default"
                              disabled={isClearingAll}
                              endIcon={isClearingAll && <CircularProgress size={16} />}
                              onClick={handleClickClearAllRecentSearches}
                            >
                              <Box component="span" px={1}>
                                <Typography component="span" variant="body1" color="textSecondary">
                                  Clear all
                                </Typography>
                              </Box>
                            </Button>
                          </Box>
                        }
                      >
                        {historyData.meetingsSearchHistory.map((item) => {
                          if (deletedHistoryItems.includes(item.id)) return null;
                          return (
                            <ListItem
                              button
                              key={item.id}
                              classes={{ container: styles.listItem }}
                              onClick={handleSearchHistoryClick(item.search)}
                            >
                              <ListItemIcon>
                                <HistoryIcon />
                              </ListItemIcon>
                              <ListItemText primary={item.search} />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  size="small"
                                  title="Delete this item from your search history"
                                  className="ghostly"
                                  onClick={handleClickClearRecentSearchItem(item.id)}
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      gridGap={18}
                      m={2}
                      py={8}
                    >
                      <img width={36} alt="Search" src={searchImage} />

                      <div>
                        <Typography gutterBottom variant="h6" align="center">
                          No recent items available
                        </Typography>
                        <Typography variant="body1" align="center">
                          Try typing to see suggestions
                        </Typography>
                      </div>
                    </Box>
                  )}
                </>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>

      {/* Begin: All Filters Dialog */}
      <GenericDialog
        title="Filter by"
        onClose={() => setShowAllFilters(false)}
        dialogProps={{
          open: showAllFilters,
          maxWidth: 'sm',
          fullWidth: true,
          keepMounted: true,
        }}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography gutterBottom variant="body1" color="textPrimary">
              <b>Date</b>
            </Typography>
            <FormControl fullWidth size="small" variant="filled">
              <Select fullWidth value={dateRangeValue} onChange={handleChangeDateRangeType}>
                {dateRangeTypes.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <Typography gutterBottom variant="body1" color="textPrimary">
              <b>Participants</b>
            </Typography>
            <FormControl fullWidth size="small" variant="filled">
              <TextField
                size="small"
                variant="filled"
                placeholder="Select participants"
                value={
                  selectedParticipants.length
                    ? `${selectedParticipants.length} ${pluralize(
                        'participant',
                        selectedParticipants.length,
                      )}`
                    : 'All participants'
                }
                InputProps={{
                  readOnly: true,
                  style: { cursor: 'pointer', paddingRight: 0, userSelect: 'none' },
                  endAdornment: (
                    <InputAdornment position="start">
                      {isLoadingParticipants ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          {!!participantsMenuEl ? (
                            <ArrowDropUpIcon color="action" />
                          ) : (
                            <ArrowDropDownIcon color="action" />
                          )}
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ style: { cursor: 'pointer' } }}
                onClick={handleOpenParticipantsMenu}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <Typography gutterBottom variant="body1" color="textPrimary">
              <b>Type</b>
            </Typography>
            <FormControl fullWidth size="small" variant="filled">
              <Select value={meetingTypeValue} onChange={handleChangeMeetingType}>
                <MenuItem value="ALL">All meeting types</MenuItem>
                {meetingTypesList.map((item) =>
                  typeof item === 'string' ? (
                    <ListSubheader key={item} classes={{ root: styles.listSubHeaderRoot }}>
                      {item}
                    </ListSubheader>
                  ) : (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      classes={{ root: styles.menuItemRoot }}
                      selected={meetingTypeValue === item.value}
                    >
                      {item.label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <Typography gutterBottom variant="body1" color="textPrimary">
              <b>Source</b>
            </Typography>
            <FormControl fullWidth size="small" variant="filled">
              <Select fullWidth value={meetingSourceValue} onChange={handleChangeMeetingSource}>
                {meetingPlatforms.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button variant="outlined" onClick={() => setShowAllFilters(false)}>
            <Typography variant="body1" color="inherit" onClick={handleClearAllFilters}>
              Clear filters
            </Typography>
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => setShowAllFilters(false)}
          >
            <Typography variant="body1" color="inherit">
              Apply
            </Typography>
          </Button>
        </DialogActions>
      </GenericDialog>
      {/* End: All Filters Dialog */}

      <Menu
        variant="menu"
        open={Boolean(participantsMenuEl)}
        anchorEl={participantsMenuEl}
        getContentAnchorEl={null}
        style={{ zIndex: 1302 }}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        classes={{ paper: styles.menuPaper, list: styles.menu }}
        onClose={(event, reason) => {
          setTempParticipantsFilterValue([]);
          setParticipantsMenuEl(null);
        }}
      >
        <div>
          <div className={styles.sticky}>
            <div className={styles.destinationHeader}>
              <div className={styles.destinationInner}>
                <Button
                  className={styles.btnCancel}
                  size="small"
                  color="primary"
                  disabled={!tempParticipantsFilterValue.length}
                  onClick={handleResetParticipantFilter}
                >
                  <Typography component="span" variant="body1">
                    Reset
                  </Typography>
                </Button>
                <Typography component="div" variant="body1">
                  <b>Select participants</b>
                </Typography>
                <Button
                  className={styles.btnDone}
                  size="small"
                  color="primary"
                  onClick={handleApplyParticipantFilter}
                >
                  <Typography component="span" variant="body1">
                    Apply
                  </Typography>
                </Button>
              </div>
            </div>
            <Divider />
            <TextField
              fullWidth
              autoFocus
              size="small"
              variant="filled"
              placeholder="Type here to participants"
              className={styles.destinationSearch}
              value={participantsFilterValue}
              InputProps={{
                endAdornment: !!participantsFilterValue && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearParticipantFilterValue}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeParticipantFilterValue}
            />

            <Divider />
          </div>
          {isLoadingParticipants ? (
            <Box display="flex" alignItems="center" justifyContent="center" py={8}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <Box my={1} overflow="auto" maxHeight={300}>
              {participantItems.map((participant, index) => (
                <MenuItem
                  key={`${index}-${participant.name}`}
                  onClick={handleClickOnParticipantItem(participant)}
                >
                  <Box display="flex" minWidth={32}>
                    <Checkbox
                      size="small"
                      color="primary"
                      classes={{ root: styles.switchRoot }}
                      checked={tempParticipantsFilterValue.some(
                        (item) =>
                          item.name === participant.name && item.email === participant.email,
                      )}
                    />
                  </Box>
                  <Typography component="span" variant="body1" className={styles.textWrap}>
                    {participant.name} {participant.email ? `[${participant.email}]` : ''}
                  </Typography>
                </MenuItem>
              ))}
              {hasMoreParticipants && (
                <Button
                  fullWidth
                  variant="text"
                  color="primary"
                  onClick={handleLoadMoreParticipants}
                >
                  <Typography component="span" variant="body1" color="inherit">
                    Load more
                  </Typography>
                </Button>
              )}
              {!participantItems.length && (
                <Box m={2}>
                  <Typography variant="body2" color="textSecondary">
                    No participants found
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </div>
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: 1299,
  },
  divider: {
    height: 20,
    alignSelf: 'center',
    margin: theme.spacing(0, 0.5),
  },
  dialogTitle: {
    gridGap: theme.spacing(2),
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  dialogContent: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(0),
    zIndex: 1,
  },
  destinationHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  destinationInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.75),
  },
  destinationSearch: {
    padding: theme.spacing(1.5, 2),
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    color: theme.palette.text.primary,
    maxHeight: '80vh',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
    },
  },
  searchFieldInput: {
    padding: theme.spacing(1.25, 1.5),
  },
  searchItem: {
    padding: theme.spacing(1, 3),
  },
  switchRoot: {
    padding: 0,
  },
  iconButton: {
    padding: theme.spacing(0.75),
  },
  textWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    flexWrap: 'wrap',
  },
  toolbarSection: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  listSubHeaderRoot: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  menu: {
    padding: 0,
  },
  menuItemRoot: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  menuPaper: {
    minWidth: '40ch',
    maxWidth: '536px',
    maxHeight: '60vh',
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    borderRadius: theme.shape.borderRadius * 4,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  actions: {
    padding: theme.spacing(1, 3, 4),
  },
  textFieldInput: {
    width: 110,
  },
  allFiltersButton: {
    height: 35,
  },
  btnDone: {
    position: 'absolute',
    right: theme.spacing(1),
  },
  btnCancel: {
    position: 'absolute',
    left: theme.spacing(1),
  },
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButtonOutlined: {
    padding: theme.spacing(1),
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  listItem: {
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    '& .ghostly': {
      visibility: 'hidden',
      [theme.breakpoints.down('xs')]: {
        visibility: 'visible',
      },
    },
    '&:hover .ghostly': {
      visibility: 'visible',
    },
  },
}));

export default AdvancedSearchDialog;
