import { useEffect, useRef } from 'react';
// Lib Shared
import { CompactRecordControl } from './CompactRecordControl';
import { FullRecordControl } from './FullRecordControl';
import { RecordControlProps } from '../types';
import { useVoiceDetection } from '../hooks';

export interface SpeakIndicatiedRecordingControlProps extends RecordControlProps {
  mediaStream: MediaStream | null;
  silenceDetectionTimeoutSeconds?: number;
  ui: 'compact' | 'full';
  onChangeState?: (speaking: boolean, isUnsupportedDetection: boolean) => void;
  onSilenceDetected?: () => void;
}

export const SpeakIndicatiedRecordingControl: React.VFC<SpeakIndicatiedRecordingControlProps> = ({
  mediaStream,
  silenceDetectionTimeoutSeconds = 0,
  ui,
  onChangeState = () => null,
  onSilenceDetected = () => null,
  ...recordingControlProps
}) => {
  /* #region  Hooks */
  const isMountedRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const segmentsRef = useRef<Float32Array>(new Float32Array(100));

  const [signalDetected, { frequencyData, isUnsupported }] = useVoiceDetection(mediaStream);
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isMountedRef.current) return;
    onChangeState(signalDetected, isUnsupported);
  }, [signalDetected, isUnsupported, onChangeState]);

  useEffect(() => {
    if (!silenceDetectionTimeoutSeconds || isUnsupported) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      return;
    }

    function notify() {
      onSilenceDetected();
      timeoutRef.current = setTimeout(notify, silenceDetectionTimeoutSeconds * 1000);
    }

    if ((signalDetected || recordingControlProps.paused) && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      return;
    }

    timeoutRef.current = setTimeout(notify, silenceDetectionTimeoutSeconds * 1000);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [
    isUnsupported,
    onSilenceDetected,
    recordingControlProps.paused,
    signalDetected,
    silenceDetectionTimeoutSeconds,
  ]);

  // Initialize canvas for visualization
  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const cssWidth = canvas.parentElement?.clientWidth || 600;
    const cssHeight = 200; // default height for the visualization
    const scale = devicePixelRatio || 1;

    canvas.width = cssWidth * scale;
    canvas.height = cssHeight * scale;
    canvas.style.width = `${cssWidth}px`;
    canvas.style.height = `${cssHeight}px`;

    // Adjust canvas for sharp rendering based on device pixel ratio
    ctx.scale(scale, scale);
  }, []);

  // Draw visualization bars based on frequency data
  useEffect(() => {
    if (isUnsupported || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const segmentCount = segmentsRef.current.length; // number of bars for smooth animation
    const segmentWidth = canvas.width / segmentCount / (devicePixelRatio || 1); // calculate segment width for even distribution
    const minHeight = 1; // minimum bar height to avoid disappearing bars

    ctx.lineCap = 'round'; // Add rounded ends to the bars for visual appeal

    let animationFrameId: number;

    const render = () => {
      if (!recordingControlProps.paused) {
        const segments = segmentsRef.current;
        const maxFrequency = Math.max(...Array.from(frequencyData));

        // This variable represents the height of a bar in the visualization.
        // It is calculated based on the maximum frequency amplitude from the `frequencyData` array.
        // The value is scaled to fit within the canvas height, with an optional minimum height to avoid disappearing bars.
        const scaledFrequency = maxFrequency
          ? Math.max(
              ((maxFrequency / 255) * canvas.height * 0.8) / (devicePixelRatio || 1),
              minHeight,
            )
          : minHeight;

        // Shift all bars to the left and add a new value on the right
        segments.copyWithin(0, 1);
        segments[segmentCount - 1] = scaledFrequency;

        // Clear and draw new bars
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        segments.forEach((height, index) => {
          const x = index * segmentWidth + segmentWidth / 2; // center the bar horizontally
          const yCenter = canvas.height / 2 / (devicePixelRatio || 1); // calculate the center of the canvas
          const yStart = yCenter - height / 2; // align bar vertically centered
          const yEnd = yCenter + height / 2;

          ctx.strokeStyle = '#05b3b3';
          ctx.lineWidth = segmentWidth - 4; // adjust width to leave space between bars
          ctx.beginPath();
          ctx.moveTo(x, yStart);
          ctx.lineTo(x, yEnd);
          ctx.stroke();
        });
      }

      animationFrameId = requestAnimationFrame(render);
    };

    animationFrameId = requestAnimationFrame(render);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [frequencyData, isUnsupported, recordingControlProps.paused]);
  /* #endregion */

  return ui === 'compact' ? (
    <CompactRecordControl
      {...recordingControlProps}
      displaySpeakingIndicator={!isUnsupported}
      speaking={signalDetected}
    />
  ) : (
    <FullRecordControl
      {...recordingControlProps}
      displaySpeakingIndicator={!isUnsupported}
      speaking={signalDetected}
    >
      <canvas
        ref={canvasRef}
        width="100%"
        height={200}
        style={{ opacity: recordingControlProps.paused ? '0.5' : '1' }}
      />
    </FullRecordControl>
  );
};

export default SpeakIndicatiedRecordingControl;
