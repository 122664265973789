// Material UI
import Box, { BoxProps } from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// Sembly UI
import GenericDialog from './GenericDialog';

export interface GenericConfirmationProps {
  actionsWrapperProps?: BoxProps;
  cancelButtonLabel?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonLabel?: string;
  confirmButtonProps?: ButtonProps;
  confirming?: boolean;
  open: boolean;
  text: string | React.ReactNode;
  textProps?: TypographyProps;
  title?: string;
  titleProps?: TypographyProps;
  titleWrapperProps?: BoxProps;
  onConfirm: () => void;
  onCancel: () => void;
}

export const GenericConfirmation: React.VFC<GenericConfirmationProps> = ({
  actionsWrapperProps,
  cancelButtonLabel = 'Cancel',
  cancelButtonProps,
  confirmButtonLabel = 'Confirm',
  confirmButtonProps,
  confirming = false,
  open,
  text,
  textProps,
  title,
  titleProps,
  titleWrapperProps,
  onConfirm,
  onCancel,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={onCancel}
    >
      <Box component={DialogContent} mt={1} mb={4}>
        <Box my={3} textAlign="center" {...titleWrapperProps}>
          {!!title && (
            <Typography paragraph variant="h5" color="primary" {...titleProps}>
              {title}
            </Typography>
          )}
          <Typography variant="body1" {...textProps}>
            {text}
          </Typography>
        </Box>
        <Box display="flex" gridGap={8} justifyContent="center" {...actionsWrapperProps}>
        <Button variant="outlined" onClick={onCancel} {...cancelButtonProps}>
            <span>{cancelButtonLabel}</span>
          </Button>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            disabled={confirming}
            startIcon={confirming && <CircularProgress size={16} />}
            onClick={onConfirm}
            {...confirmButtonProps}
          >
            <span>{confirmButtonLabel}</span>
          </Button>
        </Box>
      </Box>
    </GenericDialog>
  );
};

export default GenericConfirmation;
